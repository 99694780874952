.toolbar_veda {
  padding: 5px 10px 8px;
  align-items: center;
  display: flex;
}

.ion_titles {
  font-size: 3rem;
  padding: 18px 0px;
  line-height: 148%;
  margin-top: 0px;
}

.logout-modal {
  position: relative;
  top: 45% !important;
  transform: translateY(-10%);
}

.carousel-image {
  width: 100%;
  max-height: 325px;
  object-fit: contain;
}

.slick-dots li button:before {
  color: #fff !important;
  font-size: 10px !important;
  opacity: 0.7 !important;
}

.slick-dots li.slick-active button:before {
  color: #4a90e2 !important;
  opacity: 1 !important;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}

.slick-slider:hover .custom-arrow {
  opacity: 1 !important;
}


.custom-prev {
  left: 10px;
}

.custom-next {
  right: 10px;
}



/* Background Gradient */
.widgets_container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: 80px 0px;
  height: fit-content;
}

.scroll-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 500px;
  width: 200px;
  /* Adjust height as needed */
}

.scroll-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;
  padding: 10px;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE/Edge */
  width: 100%;
  max-height: 100%;
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, Edge */
}

.scroll-item {
  flex: 0 0 auto;
  width: 100%;
}

.scroll-arrow {
  position: absolute;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.leftArrow {
  left: 0 !important;
  top: 40% !important;
}

.rightArrow {
  right: 0px !important;
  top: 40% !important;
}

.scroll-arrow.top {
  top: -20px;
  left: 50%;

}

.scroll-arrow.bottom {
  bottom: -20px;
  left: 50%;
}

.scroll-arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}

.slick-track {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.info-card ion-card-title {
  font-size: 14px !important;
  /* Adjust as needed */
  word-wrap: break-word;
  white-space: normal;
  font-weight: 600 !important;
}

.gradient-background {
  background: linear-gradient(to bottom, #243B55, #141E30);
  color: white;
  text-align: center;
}

/* Cards Styling */
.info-card {
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 12px;
  height: 90px;
  transition: transform 0.3s ease-in-out;
}

.info-card:hover {
  transform: scale(1.05);
}


.Information-alert {
  font-weight: 700;
  margin-bottom: 20px;
}

.icon {
  width: 25px;
  height: 25px;
  margin-bottom: 10px;
}

.carousel-text-container {

  background-color: transparent;
  color: white;
  min-height: 100%;
  width: 100%;
  padding: 10px 20px;
  font-size: 25px;
}






.signup_but {
  align-items: center;
  justify-content: end;
}

.gap_between {
  padding-left: 20px;
  font-size: 13px;
  padding-right: 20px;
  font-weight: 600;
  --inner-border-width: 0 0 0px 0 !important;
  cursor: pointer;
}

.gap_between1 {
  font-size: 13px;
  font-weight: 600;
  --inner-border-width: 0 0 0px 0 !important;
  cursor: pointer;
}

.gap_between:hover {
  color: green;
}

.homeBackGround {
  background-color: #E0D7C4;

}



.homeCards IonCardTitle {
  font-size: 18px;
}

.homeCards h4 {
  font-weight: 900;
}

ion-card {
  --background: white;
  --color: var(--ion-color-primary);
}

.info-card ion-card-title {
  --color: white;
  font-style: bold;
  font-weight: 900;
  font-size: 23px;
}

ion-select {
  /* padding: 2px 0px 2px 9px; */
}

.card-content-md p {

  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;

}

.shipmentCards {
  text-align: center;
  padding: 4% 5%;
  margin: 15px;
  background-color: white !important;
  color: white;
  height: fit-content;
  cursor: pointer;
}

.shipmentCards ion-text {
  color: #000;
}

.shipmentCards ion-input {
  /* background-color: seashell; */
  color: #000;
}

.shipmentCards input {
  /* background-color: seashell; */
  color: #000;
}





.row_headers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.footer_all {
  justify-content: center;

  padding-right: 21px;
}

.row_foot {
  justify-content: center;
  margin-bottom: 15px;
}

.slash {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  background: #cf7052;
  border-radius: 20px;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
}

.gap_bet {
  padding-right: 25px;
}

.ion_row_cent {
  justify-content: left;
  text-align: left;
  margin-bottom: -3px;

}

.content_style {
  background-color: #F1FAFF !important;
}

.wid {
  width: 40%;
  margin-left: 44%;
}

.font_icon {
  font-size: 25px;
  margin-right: 5px;
  cursor: pointer;
  color: white;
}

.font_right {
  display: flex;

}

.join_us {
  margin-left: 40%;
}

.calendar {
  margin-left: 10px;
  font-size: 18px;
}

.calendar_icon {
  line-height: 3.9rem;
  padding-left: 10px;
  font-size: 26px;
}

.select_user {

  --border-width: 0px 0 0px 0;
  --inner-border-width: 0;
  --show-full-highlight: 0;
  --show-inset-highlight: 0;


}

.container_mid {
  text-align: center;
  padding-top: 43px;
}

.top {
  padding-top: 0px !important;
}

.placeholder1 {
  --placeholder-opacity: 0.7;
  width: 100%;
}

.row_bot {
  margin-bottom: 25px;
  justify-content: center;
}

.row_bot1 {
  margin-bottom: 20px;
}

@media (prefers-color-scheme: dark) {
  .dark-icon .native-input::-webkit-calendar-picker-indicator {
    filter: invert(1);
    color: black;
  }
}



.submit {
  text-transform: none;
  font-size: 25px !important;
  margin-top: 5px;
  width: 150px;
  height: 50px;

  font-weight: bold !important;
  color: #FFF !important;
  --border-radius: 49px !important;

}

.loginPhone {
  text-transform: none;
  font-size: 25px !important;
  margin-top: 5px;
  width: 300px;
  height: 50px;
  font-weight: bold !important;
  color: #FFF !important;
  --border-radius: 49px !important;
}

.search_row {
  margin-top: 2%;
}

.min_height_divider {
  min-height: 10px;
}

.min_height_divider1 {
  min-height: 0px;
}

.sign_but {
  text-transform: none;
  --background: #fff !important;
  --color: darkslategrey !important;
}

.foot_home {
  margin-top: 26%;

}

.label_align {
  color: #484848;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;



}

.textalign {
  text-align: left;
}

.kDEcCH,
.jmXKev {
  padding: 15px 25px 15px 25px !important;
}

/* 
.kDEcCH ion-button.md.button.button-solid.ion-activatable.ion-focusable,
.jmXKev ion-button.md.button.button-solid.ion-activatable.ion-focusable {
  position: absolute;
  top: 7px;
  right: -2px;
  z-index: 2;
  cursor: pointer;
  height: 2.25rem;
  --background-hover: none !important;
  --border-radius: 0px 3px 3px 0px !important;
  --box-shadow: none !important;
  height: 30px;
}

.kDEcCH ion-button.md.button.button-solid.ion-activatable.ion-focusable .button-native,
.jmXKev ion-button.md.button.button-solid.ion-activatable.ion-focusable .button-native {
  box-shadow: none !important;
  background-color: var(--ion-color-primary);

}

.kDEcCH ion-input.filter-input.sc-ion-input-md-h.sc-ion-input-md-s.md,
.jmXKev ion-input.filter-input.sc-ion-input-md-h.sc-ion-input-md-s.md {
  text-align: left;
  max-width: 12rem !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 3px;
  height: 30px;
  color: black;
  padding: 0px !important;
} */



div[role="columnheader"] {
  font-weight: bold;
}

/* ion-button.md.button.button-solid {
  font-size: 14px;
  font-weight: 100;
  --border-radius: 2px;
  text-transform: capitalize;
} */
.moreBtnMainCls {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
}

.addDeityBtnCls {
  margin: 0;
  text-transform: capitalize;
}

.addDeityBtnCls button {
  padding: 0 10px !important;
}

.gKbhqU,
.eaGqxa {
  background: #f8f8f8 !important;
  border: 1px solid #e0e0e0;
  min-height: 42px !important;
}

.modal_dialog_width {
  margin-top: auto !important;
}

ion-toolbar a {
  text-decoration: none !important;
}

.banner {
  width: 100%;
  height: auto;
}

.alert-title {
  text-align: center;
}

.alert-sub-title {
  text-align: center;
}

.alert-message {
  text-align: left;
}

.alert-button-inner {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* new UI */

.head1_style {
  color: var(--ion-bold-text-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}

.aboutveda_style {
  color: #404040;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.aboutveda2_style {
  color: #404040;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card_style {
  background: #FFF;
  margin: 15px;
  padding: 10px;
  border-radius: 15px;
}

.card_img_style {
  width: 300px;
  height: 202px;
  border-radius: 5px !important;
}

.card_head {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  padding-left: 20px;
  display: -webkit-box;

  -webkit-line-clamp: 5;

  -webkit-box-orient: vertical;
}

.card_text {
  color: #404040;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 5em;
  width: 100%;
}

.card_btn {
  --border-radius: 50px;
  text-transform: none;
  height: 60px;
}

.card_btn_text {
  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
}

.card_btn_icon {
  font-size: 30px;
  color: white;
  padding-left: 10px;
}

.card_head_main {
  display: flex;
}




/* new ui css */

.container_bg {

  display: grid;
  justify-content: center;
  background: white;
  padding: 30px;
}

.row_padding {
  margin-top: 10px;
}

.btn_row_padding {
  margin-top: 10px;
  text-align: center;
}

/* about us new ui */

.whole_container_bg {
  background: var(--ion-temple-bg) !important;
}

.container_first_half {
  height: 50%;
  padding: 20px;
}

.head_text1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 2px;
  text-align: left;
  color: var(--ion-bold-text-color);
}

.sub_head_text {
  font-size: 35px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: 0px;
  text-align: left;
}

.sub_head_text1 {
  font-size: 23px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0px;
  text-align: left;
  color: #404040;
}

.vision_content1 {
  font-size: 23px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: #404040;
}

.container_second_half {
  height: 50%;
  background: #fff;
}

.content_padding {
  padding: 20px;
}

.about_img {
  width: 500px;
  height: 500px;
}

.img_col_align {
  display: flex;
  justify-content: center;
}

.switch_columns {
  order: 1;
}

.home_card {
  padding: 10px;
  color: black !important;
  background-color: darkseagreen !important;
}

.total_number_text {
  font-weight: bold;
}



@media (min-width:1100px) and (max-width:1600px) {
  .scroll-lg-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 17px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: max-content;
  }

  .scroll-lg {
    width: 100%;
    padding: 1%;
    overflow-x: scroll;
  }

  .homeCards {
    text-align: center;
    padding: 4% 5%;
    background-color: #D1965C;
    margin: 15px;
    color: white;
    height: 190px;
    cursor: pointer;
  }
}

@media only screen and (max-width:1162px) {
  .card_img_style {
    width: 200px;
    height: 202px;
    border-radius: 5px !important;
  }

  .card_btn {
    --border-radius: 50px !important;
    text-transform: none;
    height: 50px;
  }

  .card_btn_text {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    padding: 0px;
  }

  .card_btn_icon {
    font-size: 25px;
    color: white;
    padding-left: 10px;
  }
}



@media (max-width: 1100px) {
  .homeCards {
    text-align: center;
    padding: 4% 5%;
    background-color: #D1965C;
    margin: 15px;
    color: white;
    height: 210px;
    cursor: pointer;
  }

  .slash {
    position: absolute;
    line-height: 35px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px !important;
  }

  .wid {
    width: 152% !important;
    margin-left: -150% !important;
  }

  .sign_but {
    text-transform: none;
    font-size: 36%;
    --background: #fff !important;
    --color: darkslategrey !important;
    height: 28px;
  }

  .font_icon {
    font-size: 3vw !important;
    color: white;
    cursor: pointer;
  }

  .font_right {
    display: flex !important;
    margin-left: 0px !important;
  }

  .join_us {
    display: flex;
    margin-left: 2% !important;
  }

  .gap_between {
    padding-left: 5px;
    padding-right: 14px;
    margin-bottom: 7px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    --inner-border-width: 0 0 0px 0 !important;
  }

  .row_headers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 17px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ion_titles {
    font-size: 2.1rem;
    padding: 18px 0px;
    line-height: 105%;
    margin-top: -16px !important;
  }

  .search_bar {
    width: 19%;
    margin-left: 78%;
  }

  .select_user {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;


  }

  .container_mid {
    text-align: center;
    padding-top: 0px !important;
  }

  .top {
    padding-top: 0px !important;
  }

  .row_bot {
    padding: 0px;
    margin-bottom: 9px !important;
    justify-content: center;
  }

  .submit {
    text-transform: none;
    font-size: 25px !important;
    margin-top: 5px;
    width: auto;

    font-weight: bold;
    color: #FFF !important;
    --border-radius: 49px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;

  }

  .search_row {
    margin-top: 7% !important;
  }

  .min_height_divider1 {
    min-height: 0px !important;
  }

  .textalign {
    text-align: inherit;
  }

  .select_user:host(.item-interactive) {
    border-color: blue;
  }

  .foot_home {
    margin-top: 195% !important;

  }
}

@media only screen and (max-width:1000px) {
  .logout-modal {
    position: relative;
    top: 25% !important;
    transform: translateY(-10%);
  }

  .row_bot1 {
    margin-bottom: 20px;
    justify-content: center;
  }
}


@media only screen and (max-width:840px) {
  .card_head {
    color: #222;
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;

  }

  .card_text {
    color: #404040;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 5em;
    width: 100%;
  }

  .card_btn_text {
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    padding: 0px;
  }

  .card_btn_icon {
    font-size: 20px;
    color: white;
    padding-left: 5px;
  }
}

@media only screen and (max-width:767px) {
  .card_head_main {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .card_img_style {
    width: 400px;
    height: 202px;
    border-radius: 5px !important;
  }

  .info-card ion-card-title {
    text-align: center;
    font-size: 14px;
  }

  .info-card {
    padding: 5px;
  }

  .carousel-text-container {
    font-size: 16px;
  }

}



@media only screen and (max-width:600px) {
  .logout-modal {
    position: relative;
    top: 30% !important;
    transform: translateY(-10%);
  }
}


@media only screen and (max-width:500px) {
  .widgets_container {
    width: 100%;
    padding: 20px 0px;
  }

  .scroll-container {
    flex-direction: row;
  }

  .scroll-wrapper {
    width: 100%;
  }

  .scroll-item {
    width: 120px;
  }

  .info-card ion-card-title {
    font-size: 12px !important;
  }

  ion-card-header .icon {
    width: 25px;
    height: 25px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width:440px) {
  .card_head_main {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .card_img_style {
    width: 300px;
    height: 120px;
    border-radius: 5px !important;
  }

  .card_btn_text {
    color: #FFF;
    font-size: 13px;
    font-weight: 700;
    padding: 0px;
  }

  .card_text {
    color: #404040;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 7em; */
    width: 100%;
  }

  .aboutveda_style {
    color: #404040;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
  }

  .aboutveda2_style {
    color: #404040;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .home_head_text {
    font-size: 18px;
  }

  .home_head_subtext {
    font-size: 14px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }


  .slider-container {
    width: 100%;
    overflow: hidden;
  }

  .carousel-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  .carousel-video {
    width: 100%;
    max-height: 250px;
  }
}

@media (max-width: 390px) {
  .sign_but {
    text-transform: none;
    font-size: 25% !important;
    height: 28px !important;
    --background: #fff !important;
    --color: darkslategrey !important;
  }

  .ion_titles {
    font-size: 1.9rem !important;
    padding: 18px 0px !important;
    line-height: 105%;
    margin-top: -16px !important;
  }

  .homeCards {
    text-align: center;
    padding: 4% 5%;
    background-color: #D1965C;
    margin: 15px;
    color: white;
    height: 210px;
    cursor: pointer;
  }
}

@media (max-width: 360px) {
  .sign_but {
    text-transform: none;
    font-size: 14% !important;
    height: 28px !important;
    --background: #fff !important;
    --color: darkslategrey !important;
  }

  .ion_titles {
    font-size: 1.8rem !important;
    padding: 18px 0px !important;
    line-height: 105%;
    margin-top: -16px !important;
  }

  .gap_between {
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 7px !important;
    margin-top: 10px !important;
    padding-left: 9px !important;
    padding-right: 6px !important;
    --inner-border-width: 0 0 0px 0 !important;
  }

  .homeCards {
    text-align: center;
    padding: 4% 5%;
    background-color: #D1965C;
    margin: 15px;
    color: white;
    height: 210px;
    cursor: pointer;
  }
}
.pooja_align{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}
.align_order{
    padding-left: 5%;
    padding-top: 4%;
}
.orders_align{
    display: flex;
    justify-content: end;
}
.button_center{
    display: flex;
    justify-content: center;
}
.grid_align{
    margin-left: 5%;
}
.bottom_border_list{
       
        border-bottom: 1px solid ;
}
.order_box{
    margin-left: 1%;
}
.offering_align{
    margin-left: 5%;
}
.whole{
    width: 100%;
    padding: 0% 4%;
}
.orderdetails_align{
     padding-left: 6%;
}
.cartdetails_align{
    padding-left: 6%;
}
.Off_Head_Row{
    display: flex;
    justify-content: center;
}
.Off_Tittle{
    padding: 1% 1%;
}

.off-card{
    width: 90% !important;
    height: 250px;
    border: 1px solid gray;
    margin: 2%;
    padding: 1%;
    text-align: center;
    overflow-y: auto;
}
.off-card1{
    width: 25% !important;
    height: 250px;
    border: 1px solid gray;
    margin: 2%;
    padding: 1%;
    text-align: center;
    overflow-y: auto;
}
.off-card2{
    width: 40% !important;
    height: 250px;
    border: 1px solid gray;
    margin: 2%;
    padding: 1%;
    text-align: center;
    overflow-y: auto;
}
.off-card3{
    width: 45% !important;
    height: 250px;
    border: 1px solid gray;
    margin: 2%;
    padding: 1%;
    text-align: center;
    overflow-y: auto;
}
.poojacard{
    width: 70% !important;
    height: 300px;
    border: 1px solid gray;
    margin: 2%;
    padding: 1%;
    text-align: center;
    overflow-y: auto;
}

.templeadjust1{
    padding-left: 6%;
}
.templeadjust{
    padding-left: 16%;
}

@media only screen and (max-width:1000px){
    .templeadjust{
        padding-left: 0%;
    }
    .templeadjust1{
        padding-left: 0%;
    }
} 
.txt-space{
    margin: 3% 1%;
    text-align: center;
}
.forword-back{
   color: black;
    font-size: xx-large;
}

element.style {
    display: block;
}
.slick-prev, .slick-next{
    color: #576F9F !important;
    background: #576F9F !important;
}
.slick-prev, .slick-next:hover{
    color: #576F9F;
    background: #576F9F;
}
.booknow{
    max-width: 80%;
    position: fixed;
    bottom: 20px
};

 
.mat-card {
  text-align: center;
  width: 95% !important;
  margin: 10px;
}

.mat-card1 {
  text-align: center;
  width: 40% !important;
  margin: 10px;
}

.iconsBtns {
  --background: white;
  --color: black;
  --padding-left: 5px;
}

.grammercesearch {
  width: 50%;
  margin-right: 1%;
}

.divide-total {
  border-top: 2px solid #576F9F;
  margin-top: 5%;
}

.divide {
  border-left: 2px solid #576F9F;
  padding: 2% 1%;
}

.searchInput {
  border: 1px solid #576F9F;
  max-width: 205px;
  height: 35px;
}

.searchInputmat {
  border: 1px solid #576F9F;
  max-width: 190px;
  height: 35px;
}

.no_grammerce_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

@media only screen and (max-width:977px) {
  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 290px;
    height: 35px;
  }
}

@media only screen and (max-width:882px) {
  .no_grammerce_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
}

@media only screen and (max-width:770px) {
  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 229px;
    height: 35px;
  }

  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 220px;
    height: 35px;
  }
}

@media only screen and (max-width:698px) {
  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 220px;
    height: 35px;
    margin-left: 21%;
  }
}

@media only screen and (max-width:600px) {
  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 210px;
    height: 35px;
    margin-left: 15%;
  }

  .grammercesearch {
    width: 50%;
    margin-right: 1%;
  }
}

@media only screen and (max-width:425px) {
  .searchInput {
    border: 1px solid #576F9F;
    max-width: 300px;
    height: 35px;
  }

  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 200px;
    height: 35px;
    margin-left: 0%;
  }

  .grammercesearch {
    width: 50%;
    margin-right: 1%;
  }
}

@media only screen and (max-width:375px) {
  .searchInput {
    border: 1px solid #576F9F;
    max-width: 300px;
    height: 35px;
  }

  .grammercesearch {
    width: 100%;
    margin-right: 1%;
  }
}

@media only screen and (max-width:330px) {
  .searchInputmat {
    border: 1px solid #576F9F;
    max-width: 310px;
    height: 35px;
    margin-left: 0%;
  }
}

.TextInput {
  border: 1px solid #576F9F;
  max-width: 300px;
  height: 35px;
}

.heading_align {
  margin-left: 30%;
}

.HeadingTag {
  font-weight: bold;
  /* padding-left: 10%; */
}

.Col1 {
  margin-left: 5%;
  margin-right: 3%;
}

.MRow {
  padding-left: 3%;
  margin-top: 4%;
}

.MRow_new {
  padding-left: 3%;

}

.mat_bookscroll {
  width: 100%;
}

.Row {
  display: grid;
  justify-items: center;
  align-items: center;
}

.giftCheck {
  margin-left: 50%;
}

.MRow1 {
  margin-left: 3%;
  margin-top: 4%;
  width: 90%;
  border-top: 2px solid #576F9F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MRow2 {
  padding-left: 5%;
  margin-top: 4%;
  /* margin-right: 3%; */
  /* border-top: 5px solid #576F9F; */
}

.TextInputStyle {
  border: 2px solid #576F9F;
  height: 30px;
}

.TextInputStyle1 {
  border: 2px solid #576F9F;
  height: 30px;
  width: 100%;
}

.Img {
  /* display: flex; */
  /* align-items: center; */
  /* justify-self: center ; */
  height: 150px;
  width: 100px;
  /* margin-left: 35%;   */
  position: relative;

}

.SIcon {
  position: absolute;
  padding-left: 50%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0px;
  margin-bottom: 2px;

}

.head {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 2px;
  font-weight: bold;
  color: black;

}

.SImg {
  pointer-events: none;
  width: 125px;
  height: 100px;
  /* user-drag: none; */
}

/* .button {
  size: small;
  color: rgb(29, 22, 22);
  font-weight: 500;
  background-color: #576F9F;
} */
.grammerce-fnts {
  font-size: 16px
}

@media only screen and (max-width:575px) {
  .giftCheck {
    margin-left: 0%;
  }

  .Row {
    display: grid;
    padding: 0% 10%;
  }
}

@media only screen and (max-width:991px) {
  .divide {
    border-left: none;
    /* padding: 2% 15%; */
  }
}

@media only screen and (max-width:600px) {
  .divide {
    border-left: none;
    width: 700px !important;
    overflow-x: auto !important;
    /* padding: 2% 15%; */
    margin-top: 25px;
  }

  .MRow_new {
    padding-left: 3%;
    width: 600px !important;
    overflow-x: auto !important;
  }

  .MRow {
    padding-left: 3%;
    margin-top: 4%;
    width: 600px !important;
    overflow-x: auto !important;
  }

  .mat_bookscroll {
    width: 600px !important;
    overflow-x: auto !important;
    padding: 0px;
  }

}

@media only screen and (max-width:400px) {
  .grammerce-fnts {
    font-size: 14px
  }

  .heading_align {
    margin-left: 5%;
  }
}


/***************************** New Ui *************************/

.grammerce_main_container {
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: var(--ion-content-background-color) !important;
  min-height: 40vh;
}

.grammerce_head {
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 2px;
  color: var(--ion-bold-text-color);
}

.grammerce_top_row {
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
}

.grammerce_search_div {
  display: flex;
  width: 350px;

}

.grammerce-location_div {
  display: flex;
  border: 1px;
  border-radius: 8px;
  width: 350px;
  height: 68px;
  background: var(--ion-location-background);
  cursor: pointer;
  align-items: center;
  gap: 30px;

}

.grammerce_head_container {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grammerce_main_cart_btn {
  width: 180px;
  height: 60px;
}

.grammerce_location_icon {
  height: 32px;
  width: 32px;
  margin-left: 15px;
  color: var(--ion-bold-text-color);
}

.grammerce_location_text {
  font-size: 24px;

  line-height: 64px;
  letter-spacing: 0em;
  color: var(--ion-color-step-250);
}

.grammerce_list_div {
  width: 100%;
}

.grammerce_list_items {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
}

.grammerce_list_img_container {
  height: 277px;
  border-radius: 5px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.grammerce_list_items_img {
  width: 122px;
  height: 157px;
}

.grammerce_height {
  line-height: 35px;
}

.grammerce_product_name {
  font-size: 20px;
  font-weight: 400;
  color: var(--ion-color-step-250);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.grammerce_price {
  font-size: 22px;
  font-weight: 700;
  color: #244381;
}

.grammerce_stock {
  font-size: 19px;
  font-weight: 500;
  color: var(--ion-color-step-250);
}

.grammerce_days {
  font-size: 16px;
  font-weight: 700;
  color: #ABABAB;
}

.grammerce_outofStock {
  font-size: 19px;
  font-weight: 100;
  color: lightcoral;
}

.grammerce_cart_show ion-button {
  border-radius: 0px !important;
}

.grammerce_cart_div {
  height: 52px;
  width: 100%;
  border-radius: 0px !important;
  background: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.grammerce_cart_show ion-button {
  --border-radius: 0px !important;
}

.grammerce_cart {
  display: none;
  align-items: end;
  justify-content: flex-end;
  width: 100%;
  height: 25%;
}

.grammerce_cart_show {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.grammerce_img {
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grammerce_cart_btn {
  font-size: 19px;
  font-weight: 700;
  color: var(--ion-color-primary-contrast);
  border-radius: 0px 0px 5px 5px !important;
}

.grammerce_item_view {
  /* width: 700px;
  height: 640px; */
  border-radius: 15px;
  background: var(--ion-color-primary-contrast);
  display: flex;
  align-items: center;
  justify-content: center;
}

.grammerce_item_Img {
  width: 300px;
  height: 520px;
  padding: 20px;
  cursor: pointer;
}

.grammerce_item_details_container {
  /* width: 700px;
  height: 640px; */
  padding-left: 30px;
}

.grammerce_item_details_container>div:not(:first-child) {
  margin-top: 10px;
}

.grammerce_item_name {
  font-size: 35px;
  font-weight: 700;
  line-height: 64px;
  color: var(--ion-color-step-250);
}

.grammerce_item_price {
  font-size: 38px;
  font-weight: 700;
  line-height: 64px;
  color: #244381;
}

.grammerce_item_significance {
  font-size: 22px;
  font-weight: 400;
  line-height: 43px;
  color: #707070;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-align: justify;
}

.grammerce_item_significance.expanded {
  -webkit-line-clamp: unset;
  /* Show all lines when expanded */
  overflow: unset;
}

.see_more {
  font-size: 27px;
  font-weight: 700;
  line-height: 43px;
  cursor: pointer;
  color: #3485FF;
  text-decoration: none;
  display: block;
  margin-top: 5px;
  width: max-content;
}

.grammerce_item_stock {
  font-size: 21px;
  font-weight: 400;
  line-height: 43px;
  color: #5C5C5C;
}

.grammerce_progress_bar {
  --background: #D9D9D9 !important;
  border-radius: 12px 12px 12px 12px;
  height: 10px !important;
}

.grammerce_progress_bar .progress-bar-determinate .progress {
  --background: var(--ion-color-secondary) !important;
  border-radius: 12px 12px 12px 12px !important;
}

.grammerce_item_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.grammerce_item_cart_btn {

  --background: #FFFFFF !important;
  color: #576F9F !important;

}



.grammerce_item_cart_add_btn {
  display: flex;
  align-items: center;
  height: 54px;
  border-radius: 49px 0px 0px 49px;
  background: #FFFFFF;
  color: #939393 !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  padding: 25px;
}

.grammerce_item_cart_sub_btn {
  display: flex;
  align-items: center;
  height: 54px;
  border-radius: 0px 49px 49px 0px;
  background: #FFFFFF;
  color: #939393 !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  padding: 25px;
}

.grammerce_item_cart_input {
  text-align: center;
  width: 61px;
  height: 54px;
  padding: 0px !important;
  border: 0px !important;
  /* border-left: 2px solid #B3B3B3 !important;
  border-right: 2px solid #B3B3B3 !important; */
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  color: var(--ion-border-color) !important;
  --padding-start: 0px !important;
}

.grammerce_item_btn {
  width: 159px;
  height: 54px;
  background: #FFFFFF;
  border-radius: 49px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  justify-content: space-between;
  margin-right: 10px;
  user-select: none;
}

.grammerce_item_btn * {
  color: #939393;
  font-weight: 700;
  font-size: 22px;
}

.grammerce_item_btn ion-icon {
  cursor: pointer;
  padding: 5px;
  color: #939393;
}

.grammerce_cart_list_head {
  border-radius: 6px;
  background-color: #F3F3F3;
  padding: 10px 20px 10px 20px;
}

.grammerce_main_container2 {
  padding: 50px 100px;
  background-color: var(--ion-color-primary-contrast) !important;
}

.grammerce_cart_list_head_text {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #3D3D3D;
}

.grammerce_cart_list_item {
  display: flex;
  align-items: center;
  padding: 10px 10px 15px 0px;
  border-bottom: 1px solid #939393;
}

.grammerce_cart_item_img_div {
  min-width: 130px !important;
  min-height: 154px !important;
  border-radius: 4px;
  background-color: #F3F3F3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grammerce_cart_item_img {
  width: 96px !important;
  height: 117px !important;
}

.grammerce_cart_item_img_col {
  /* padding: "5px 0px"; */
  /* text-align: "left"; */
  display: flex;
  align-items: center;
  gap: 25px;
}

.grammerce_cart_item_remove {
  /* font-size: 17px; */
  font-weight: 400;
  line-height: 64px;
  color: #A7A4A4;
  cursor: pointer;
}

.grammerce_cart_item_remove_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  user-select: none;
}

.grammerce_cart_item_text {
  font-size: 21px;
  font-weight: 400;
  line-height: 45px;
  color: #3D3D3D;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.grammerce_cart_item_price {
  font-size: 21px;
  font-weight: 400;
  line-height: 45px;
  color: #244381;
}

.grammerce_cart_item_btn {
  width: 159px;
  height: 54px;
  background: #F8F8F8;
  border-radius: 49px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  justify-content: space-between;
  margin-right: 10px;
  user-select: none;
}

.grammerce_cart_item_btn * {
  color: #939393;
  font-weight: 700;
  font-size: 22px;
}

.grammerce_cart_item_btn ion-icon {
  cursor: pointer;
  padding: 5px;
  color: #939393;
}

.grammerce_cart_add_btn {
  display: flex;
  align-items: center;
  height: 54px;
  border-radius: 49px 0px 0px 49px;
  background: #F8F8F8;
  color: #939393 !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  padding: 25px;
}

.grammerce_cart_sub_btn {
  display: flex;
  align-items: center;
  height: 54px;
  border-radius: 0px 49px 49px 0px;
  background: #F8F8F8;
  color: #939393 !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 44px;
  padding: 25px;
}

.grammerce_cart_total_div {
  /* width: 330px; */
  height: 214px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 46px 0px #00000014;
  padding: 35px;
  margin-left: 10px;
}

.grammerce_cart_total_text {
  font-size: 23px;
  font-weight: 700;
  line-height: 64px;
  color: var(--ion-color-step-250);
}

.grammerce_cart_total_amt {
  font-size: 26px;
  font-weight: 700;
  line-height: 64px;
  color: var(--ion-border-color);
}

.grammerce_cart_amt_div {
  display: flex;
  justify-content: space-between;

  padding-bottom: 15px;
}

.grammerce_cart_amt_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grammerce_confirm_list_head {
  border-radius: 7px 7px 0px 0px;
  background-color: #DBDBDB;
  padding: 10px 20px 10px 20px;
  /* margin-top: 25px; */
}

.grammerce_confirm_list_head_text {
  font-size: 28px;
  font-weight: 500;
  line-height: 43px;
  color: var(--ion-border-color);
}

.grammerce_confirm_input {

  color: var(--ion-border-color) !important;
  border-radius: 8px;
}

.grammerce_confirm_input .PhoneInputInput {
  color: var(--ion-border-color) !important;
}

.grammerce_confirm_details_container {
  background: #FFFFFF;
  box-shadow: 0px 0px 44px 0px #0000000A;
  border-radius: 0px 0px 7px 7px;
  padding: 20px;
}

.grammerce_confirm_textarea {
  border-radius: 8px;

  background: #FFF;
  height: 60px;
  overflow-y: auto;
  color: var(--ion-border-color) !important;
}

.grammerce_confirm_sub_container {
  padding-right: 20px;
}



.grammerce_confirm_btn_container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grammerce_confirm2_container {
  /* border: 1px solid #000000; */
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  row-gap: 10px;
  height: fit-content !important;
}

.grammerce_confirm2_heaed_text {
  font-size: 20px;
  font-weight: 400;
  /* line-height: 64px; */
  color: #B1B1B1;
}

.grammerce_confirm2_col {
  display: flex;
  align-items: center;
  justify-content: right;
}

.grammerce_confirm2_list_head {
  border-radius: 7px 7px 0px 0px;
  background-color: #DBDBDB;
  padding: 16px 20px 16px 20px;
}

.grammerce_confirm2_list_head_text {
  color: #404040;
  ;
  font-size: 23px;
  font-style: normal;

  line-height: normal;
}

.grammerce_confirm2_list_text {
  font-size: 21px;
  line-height: 19px;
  color: #484848;

}

.grammerce_confirm2_list_container {
  border-radius: 0px 0px 7px 7px;
  background-color: #FFFFFF;
  padding: 10px 20px 10px 20px;
}

.grammerce_border_div {
  border-bottom: 2px solid #BEBEBE;
  width: 100%;
  padding: 10px;
}

.grammerce_confirm2_total_text {
  color: #404040 !important;
  ;
  font-size: 33px !important;
  font-style: normal;

  line-height: normal !important;

}

.grammerce_confirm2_total_amt {
  font-size: 33px;
  line-height: normal;

}

.grammerce_confirm2_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0px 10px 0px;
}

.alert_btn {
  padding: 0px;
  height: 40px;
}

.grammerce_cart_back {
  display: flex;
  gap: 40px;
  align-items: center;
}

.cart-show-for-mobile-grammerce {
  display: none;
}

.grammerce_cart_order_btn {
  margin-top: 10px;
}

.grammerce_confirm_row {
  padding: 10px 0px 10px 0px;
}

.grammerce_confirm_mobile_row {
  display: none;
}

.grammerce_confirm2_amt_div {
  margin: 20px 30px 0px 0px
}

.grammerce_confirm2_items_div {
  display: flex;
  gap: 40px;
  align-items: center
}

.grammerce_confirm2_total_amt_div {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: right
}

.grammerce_main_cart_btn_mobile {
  display: block;
  color: var(--ion-color-primary);
  font-size: 25px;
}

.cart-badge-parent {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 0px
}

.cart-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 50%;
}

.cursor_block {
  cursor: not-allowed !important;
  width: max-content;
}

.cart-total-view-grammerce {
  width: 100%;
  height: 214px;
  background: #FFF;
  box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.08);
  margin: 30px 15px;
  text-align: center;
}

.grammerce_cart_list_head {
  margin-top: 30px;
}

@media only screen and (max-width:992px) {
  .cart-total-view-grammerce {
    width: 262px;
    height: auto;
    padding-bottom: 30px;
    margin: 0 auto;
  }

  .grammerce_cart_list_head {
    margin-top: 10px;
  }
}

@media only screen and (min-width:992px) and (max-width:1200px) {
  .grammerce_main_container2 {
    padding: 50px 50px !important;
  }
}

@media only screen and (max-width:575px) {
  .grammerce_main_container {
    padding: 130px 20px 30px 20px !important;
  }

  .grammerce_head {
    font-size: 20px;
    line-height: normal;
    letter-spacing: 2px;
  }

  /* .grammerce_top_row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    padding-right: 0px !important;
  } */

  .grammerce_location_text {
    font-size: 18px;
    line-height: 0px;
  }

  .grammerce_list_items {
    padding: 0px;
    margin-top: 10px;
  }

  /* .grammerce_head_container {
    margin: 0px;
  } */

  .grammerce_list_img_container {
    height: 200px;
    cursor: pointer;
  }

  .orders-searchBar {
    max-height: 100%;
    /* width: 100%; */
  }

  .grammerce_product_name {
    font-size: 18px;
  }

  .grammerce_price {
    font-size: 24px;
  }

  .grammerce_outofStock {
    font-size: 17px;
  }

  .grammerce_stock {
    font-size: 17px;
  }

  .grammerce_days {
    font-size: 15px;
  }

  .grammerce_main_cart_btn {
    /* font-size: 20px; */
    display: none;
  }

  .grammerce_main_cart_btn_mobile {
    font-size: 22px;
  }

  .grammerce_item_view {
    max-height: 100%;
  }

  .grammerce_item_Img {
    height: 250px;
  }

  .grammerce_item_details_container {
    padding-left: 0px;
  }

  .grammerce_item_name {
    font-size: 30px;
    line-height: 30px;
  }

  .grammerce_item_price {
    font-size: 30px;
    line-height: 30px;
  }

  .grammerce_item_cart_btn {
    max-width: 100%;
  }

  .grammerce_item_btn {
    padding: 0px 5px;
  }

  .grammerce_cart_back {
    gap: 10px;
  }

  .grammerce_confirm_list_head_text {
    font-size: 24px;
  }

  .grammerce_confirm2_list_text {
    font-size: 17px !important;
    word-wrap: normal;
  }

  .grammerce_height {
    line-height: revert;
  }

  .grammerce_cart_total_text {
    font-size: 20px;
  }

  .grammerce_cart_total_amt {
    font-size: 23px;
  }

  .grammerce_cart_total_div {
    height: fit-content;
    padding: 25px;
  }

  .grammerce_cart_amt_btn {
    padding-top: 10px;
  }
}

@media only screen and (max-width:1000px) {

  .grammerce_cart_show,
  .grammerce_cart {
    display: none;
  }
}

@media only screen and (max-width:960px) {
  .grammerce-location_div {
    width: max-content;
    gap: 15px;
    padding-right: 15px;
  }

  .grammerce_top_row {
    padding-right: 20px;
  }

  .grammerce_search_div {
    width: max-content;
  }

  .grammerce_item_cart_btn {
    max-width: 100%;
    font-size: 18px !important;
  }

  .grammerce_item_btn {
    max-width: 100%;
  }

  .grammerce_item_btn * {
    max-width: fit-content;
  }

  .grammerce_item_btn ion-icon {
    max-width: 100%;
  }

  .grammerce_total_col_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (max-width:882px) {
  .grammerce_main_container {
    padding: 130px 50px 50px 50px;
    min-height: 100vh;
  }

  /* .grammerce_search_div{
    width: 295px;
  } */
}

@media only screen and (max-width:800px) {
  .grammerce_item_btn {
    height: 40px;
  }

  .grammerce_item_btn * {
    font-size: 18px;
  }
}

@media only screen and (max-width:710px) {
  .grammerce-location_div {
    width: 267px;
  }

  .grammerce_location_text {
    font-size: 18px;
  }
}

@media only screen and (max-width:660px) {

  .grammerce-location_div {
    width: 250px;
  }

  .grammerce_location_text {
    font-size: 16px;
  }

}

@media only screen and (max-width:768px) {

  .grammerce_cart_list_head {
    display: none;
  }

  .cart-hide-for-mobile-grammerce {
    display: none !important;
  }

  .cart-show-for-mobile-grammerce {
    display: flex;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.08);
    margin: 20px 0px;
    padding: 10px;
  }

  .grammerce_cart_order_btn {
    padding: 0px;
  }

  .grammerce_confirm_sub_container {
    padding-right: 0px;
    padding: 0px;
  }

  .grammerce_confirm_sub_container ion-row ion-col:nth-child(3) ion-text,
  .grammerce_book_col2 ion-row ion-col:nth-child(3) ion-text {
    display: none;
  }

  .grammerce_confirm_btn {
    height: 50px;
  }

  .grammerce_confirm2_list_head {
    display: none;
  }

  .grammerce_confirm_row {
    display: none;
  }

  .grammerce_confirm_mobile_row {
    display: block;
    border-bottom: 1px solid #BEBEBE;
  }

  .grammerce_border_div {
    display: none;
  }

  .grammerce_confirm2_list_container {
    margin-top: 10px;
    border-radius: 7px;
    padding: 10px;
  }

  .grammerce_confirm2_list_head_text {
    font-size: 20px;
  }

  .grammerce_confirm2_list_text {
    font-size: 18px;
    word-wrap: normal;
  }

  .grammerce_confirm2_total_text {
    font-size: 20px !important;
  }

  .grammerce_confirm2_total_amt {
    font-size: 20px;
  }

  .grammerce_confirm2_amt_div {
    margin: 20px 0px;
  }

  .grammerce_confirm2_items_div {
    justify-content: right;
    gap: 10px;
  }

  .grammerce_confirm2_total_amt_div {
    gap: 10px;
  }

  .grammerce_confirm2_btn_container {
    padding: 0px;
  }

  .grammerce_confirm2_btn_container ion-button {
    max-width: 100%;
  }

  .grammerce_confirm2_btn_container ion-button span {
    font-size: 16px;
  }

  .grammerce_confirm2_container2 {
    padding: 0px !important;
  }

  .grammerce_confirm_mobile_row ion-row {
    margin-top: 5px;
  }

  .grammerce_book_col2 {
    padding: 0px;
  }

  .grammerce_main_container2 {
    padding: 130px 20px 20px 20px;
  }

  .garmmerce-close-Icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: fit-content;
  }

  .garmmerce-close-Icon p:first-child {
    margin-top: 5px;
  }

  .grammerce_confirm_details_container ion-checkbox {
    --size: 16px;
  }

  .grammerce_cart_item_price {
    font-size: 14px;
  }
}

@media screen and (min-width: 320px) and (max-width: 642px) {
  .grammerce_top_row {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 0px 10px;
  }

  .grammerce-location_div {
    max-width: 45%;
    height: 50px;
    gap: 15px;
  }

  .grammerce_search_div {
    max-width: 45%;
    height: 50px;
  }

  .grammerce_search_div .orders-searchBar {
    max-width: 100%;
    height: 100%;
  }

  .grammerce_location_text {
    line-height: 0px;
  }

  .grammerce_list_items {
    padding: 10px 5px;
  }

  .grammerce_price {
    font-size: 18px;
  }

  .grammerce_head_container {
    margin: 0px 10px;
  }

  .grammerce_location_icon {
    height: 22px;
    width: 22px;
    margin-left: 5px;
  }

  .grammerce_location_text_wrapper {
    max-width: calc(100% - 24px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 350px) and (max-width: 575px) {
  .grammerce_item_btn {
    padding: 0px 15px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .grammerce_item_btn {
    padding: 0px 15px;
  }
}
.poojamat-card {
    text-align: center;
    width: 80% !important;
    margin: 5px;
}

.grmce-colm {
    padding: 0px 50px;
    text-align: center;
}

.grmce-colm1 {
    padding: 0px 50px;
    text-align: center;
    height: 380px;
}

.templeitemscroll {
    height: 535px
}

.templenamescroll {
    margin-top: 2%;
    overflow-y: auto;
    width: 100%;
    height: 670px;
}

.grmce-colm2 {
    padding: 0px 5%;
    text-align: center;
    height: 380px;
}

.itemscroll {
    height: 380px;
    width: 100%;
    overflow-y: auto;
}

.divide-total {
    border-top: 2px solid #576F9F;
    margin-top: 5%;
}

.divide {
    border-left: 2px solid #576F9F;
    padding: 2% 1%;
}

.TextInput {
    border: 1px solid #576F9F;
    max-width: 300px;
    height: 35px;
}

.heading_align {
    margin-left: 30%;
}

.calendarIcon {
    inset: 1px 20px 0px 0px;
    padding-right: 4px;
    padding-top: 2px;
}

.calendarIconAlert {
    inset: -15px 20px 0px 0px;
    padding-right: 4px;
}

.calendarIconAlertto {
    inset: -8px 20px 0px 0px;
    padding-right: 4px;
}

.HeadingTag {
    font-weight: bold;
    /* padding-left: 10%; */
}

.Col1 {
    margin-left: 5%;
    margin-right: 3%;
}

.TextInputStyle {
    border: 2px solid #576F9F;
    height: 30px;
}

.TextInputStyle1 {
    border: 2px solid #576F9F;
    height: 30px;
    width: 100%;
}

/* .button {
    size: small;
    color: black;
    font-weight: 500;
    background-color: #576F9F;
} */

.pro-card1 {

    text-align: center;

    width: 90% !important;

    margin: 10px;

}

/* input[type="date"] ::-webkit-calendar-picker-indicator {
    color: wheat;
} */

.web_calendar {
    width: 100%;
    height: 40px;

    border-radius: 8px;
}

.androidcalendar {
    width: 100%;
    /* padding-bottom: 10px !important; */

    border-radius: 8px;
    height: 40px;
}

.fromdate_row_container {
    height: auto;
}

@media (prefers-color-scheme: dark) {
    .defaultcalendar {
        display: block;
        width: 100%;
        /* padding: 5px 0px; */
    }

    .listdatedefault {
        display: block;
        padding: 0%;
    }

    .web_calendar {
        width: 100%;
        height: 40px;

        border-radius: 8px;
    }
}

@media only screen and (min-width:700px) {
    .defaultcalendar {
        display: block;
        width: 100%;
        /* padding: 5px 0px; */
    }

    .listdatedefault {
        display: block;
        padding: 0%;
    }

    .materialdefaultdate {
        display: block;
        padding: 0%;
        cursor: pointer;
    }

    .iconcalendar {
        display: none;
    }

    .defaultshow {
        display: flex;
    }

    .mobileshow {
        display: none;
    }
}

@media only screen and (max-width:700px) {
    .defaultcalendar {
        display: none;
    }

    .defaultshow {
        display: none;
    }

    .mobileshow {
        display: flex;
    }

    .listdatedefault {
        display: none;
    }

    .materialdefaultdate {
        display: none;
    }

    .iconcalendar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 10px;
    }

    .fromdate_row_container_height {
        height: 88px;
    }

    .todate_row_container_height {
        height: 70px;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
    margin-bottom: 2px;

}

.head {
    padding-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 2px;
    font-weight: bold;
    color: black;
}

.confrombookheight {
    height: 200px;
    overflow-y: auto;
}

.temple_list_container {
    padding: 3%;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:758px) {
    .confrombookheight {
        height: auto;
    }
}

@media only screen and (max-width:991px) {
    .divide {
        border-left: none;
        padding: 2% 15%;
    }

    .grmce-colm1 {
        padding: 0% 7%;
        text-align: center;
        height: 430px;
    }

    .grmce-colm2 {
        padding: 0 14%;
        text-align: center;
        height: 450px;
    }

    .itemscroll {
        height: auto;
        width: 100%;
    }

    .templeitemscroll v {
        height: 500px;
    }

    .templenamescroll {
        margin-top: 0.5%;
        overflow-y: auto;
        width: 100%;
        height: 684px;
    }

    .oo {
        border-left: 0px;
    }

    .temple_list_container {
        padding: 3%;
        width: 100%;
        height: 100%;
        padding-left: 13%;
    }
}

@media only screen and (max-width:850px) {
    .temple_list_container {
        padding: 3%;
        width: 100%;
        height: 100%;
        padding-left: 8%;
    }
}

.search_display {
    display: none;
}

@media only screen and (max-width:800px) {
    .templenamescroll {
        margin-top: 0.5%;
        overflow-y: auto;
        width: 100%;
        height: 350px;
    }

    .temple_list_container {
        padding: 3%;
        width: 100%;
        height: 100%;
        padding-left: 0%;
    }

    .search_display {
        display: flex;
    }
}

.before_search_text {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 2px;
}

@media only screen and (max-width:780px) {
    .temple_list_container {
        padding: 3%;
        width: 100%;
        height: 100%;
        padding-left: 4%;
    }
}

@media only screen and (max-width:400px) {
    .grmce-colm {
        padding: 0% 15%;
        text-align: center
    }

    .heading_align {
        margin-left: 5%;
    }
}


/*----------New UI----------*/

.order-container {
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 50px;

    background: #EFF1F5 !important;
}


.orders-select-search-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 34px 20px;
}

.no-materials {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #404040;
    font-size: 21px;
    width: 100%;
    min-height: 150px;
}

@media only screen and (max-width:882px) {
    .no-materials {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: #404040;
        font-size: 21px;
        width: 100%;
        min-height: 50vh;
    }
}

.orders-deity-select {
    width: 430px;
    height: 68px;
    border-radius: 8px;

    background: white !important;
    flex-shrink: 0;
    color: #404040 !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    padding: 0px 34px;
}

.orders-searchBar {
    width: 330px;
    height: 68px;
    background: white !important;

    border-radius: 8px;

}

.orders-searchBar * {

    box-shadow: none !important;
    color: #484848 !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 64px;
}

.material_img {
    width: 150px !important;
    margin: 0 auto;
}

.material-cards {
    padding: 10px 15px;
}

.material-card {
    background: #FFF;
    border-radius: 5px;
    height: 277px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
}

.order-material-title {
    margin-top: 10px;
    margin-left: 10px;
}

.material-card-disable {
    background: #FFF;
    border-radius: 5px;
    width: 100%;
    height: 277px;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
}

.addtocartBtn-disable {
    display: none;
}

.addtocart_btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;

    height: 52px;
}

.addtocartBtn {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    --border-radius: 0px 0px 5px 5px !important;
}

.addtocartBtn span {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    color: #FFF;
    line-height: 64px;

}

.material-content {
    padding: 5% 0%;
}

.material-stock {
    font-size: 19px;

    font-style: normal;
    color: #404040;

}

.material-name-size span {
    color: #404040;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.material-price {
    color: #244381;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 44px;

}



.materials-page-header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
}

.mat_main_cart_btn {
    width: 180px;
    height: 60px;
    display: block;
}

.mat_main_cart_btn_mobile {
    display: none;
}

.materialView-img-container {
    width: 100%;
    height: 586px;
    background: #FFF;
    border-radius: 15px;
}

.material-details-container {
    padding-left: 60px;
}

.material-image-view {
    width: 90%;
    height: 580PX;
    margin: 0 auto;
    cursor: pointer;
}

.details-productName {
    color: #404040;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
}

.details-price {
    color: #244381 !important;
    font-size: 38px;
    font-weight: 700;
    font-style: normal;
    line-height: 64px;
    width: 245px;
}

.cart-item-img .details-price {
    line-height: 20px;
}

.cart-show-for-mobile .details-price {
    font-size: 14px !important;
}

.details-price-review {
    display: flex;
    align-items: center;
}

.stars-img {
    width: 102px;
    max-height: 60px;
}

.reviews-txt {
    color: #969696;
    font-size: 18px;
    font-weight: 400;
}

.details-description p {
    line-height: 43px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 27px;
    font-weight: 400;
    color: #707070;

}

.details-progressBar {
    width: 337px;
    height: 9px;
    border-radius: 12px 0px 0px 12px;
    --background: #D9D9D9 !important;
}

.details-progressBar .progress {
    --background: var(--ion-bold-text-color) !important;
    border-radius: 12px 0px 0px 12px;
}

.details-available-stock {
    color: #5C5C5C;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
}

.details-quantity-element {
    margin-top: 24px;
}

.increment-decrement-element {
    width: 159px;
    height: 54px;
    background: #FFF;
    border-radius: 49px;
    display: flex;
    align-items: center;
    padding: 0 22px;
    justify-content: space-between;
    margin-right: 10px;
    user-select: none;
}

.increment-decrement-element ion-input {
    border: none !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: #222 !important;
    text-align: center;
    padding: 8px !important;
}

.increment-decrement-element ion-input input {
    padding: 0px !important;
}

.increment-decrement-element ion-button {
    --background: none;
    --margin-top: 0px;
    width: auto;
    height: fit-content !important;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 700;
    line-height: auto !important;
    color: #FFF !important;
    --border-radius: 0px !important
}

.increment-decrement-element ion-icon {
    cursor: pointer;
}

.increment-decrement-element * {
    color: #939393;
    font-weight: 700;
    font-size: 22px;
}

.view-cart-btn {

    --background: #FFF !important;
}

.iconcalendarMat {
    display: none;
}

.listdatedefaultMat {
    display: block;
}

.iconcalendarMat ion-icon {
    color: red;
}

.quantity-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.view-cart-btn span {
    color: #576F9F;

}

.buyNow-btn-element {
    padding: 34px 0px;
}

.buyNow-btn {
    width: 100% !important;
}

.shoppingcart-container {
    background: #FFF !important;
}

.cartItems-header {
    border-radius: 6px;
    background: #F3F3F3;
    height: 67px;
    align-items: center;
    padding: 0px 20px;
    margin-top: 30px;
}

.cart-item-row {
    align-items: center;
    padding: 28px 0px;
    border-bottom: 2px solid #939393;
}

.cart-item-row,
.cartItems-header ion-col:not(:first-child) {
    text-align: center;
}

.cart-item-img {
    display: flex;
    align-items: center;
}

.listofdatemat {
    width: 100% !important;
}

.cart-item-productName {
    margin-left: 23px;
    text-align: left !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
}

.cart-items-txt {
    font-size: 21px;
    font-weight: 400;
    color: #3D3D3D;
    text-align: center;
}

.cartItems-header span {
    font-size: 20px;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 64px;
}

.cartImg-view {
    width: 130px;
    height: 154px;
    background: #F3F3F3;
    border-radius: 4px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.material-cartImg {
    width: 100%;
    height: 117px;
}

.cart-inc-dec {
    width: 159px;
    height: 54px;
    background: #F8F8F8;
    border-radius: 49px;
    margin: 0 auto;
    user-select: none;
}

.cartRem-txt {
    color: #A7A4A4;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    line-height: 64px;
    cursor: pointer;
}

.cart-remove-element {
    display: flex;
    align-items: center;
    user-select: none;
}

.cart-remove-icon {
    color: #A7A4A4;
    cursor: pointer;
}

.cart-total-view {
    width: 100%;
    height: 214px;
    background: #FFF;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.08);
    margin: 30px 15px;
    text-align: center;
}

.cart-totalAmt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29px 20px 29px 27px;
}

.cart-totalAmt:first-child {
    color: #5B5B5B;
    font-size: 23px;
    font-weight: 700;
    font-style: normal;
}

.cart-totalAmt:nth-child(2) {
    color: #222;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}

.cart-order-btn {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.booking-details-header {
    border-radius: 7px 7px 0px 0px;
    background: #DBDBDB;
    padding: 16px 70px 8px 70px;
}

.booking-details-header h4 {
    color: #222;
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    line-height: 43px;
    padding: 0px;
}

.booking-details-header span {
    color: #404040;
    ;
    font-size: 23px;
    font-style: normal;

    line-height: normal;
}


.booking-labels-markers {
    width: 16px;
    height: 16px;
}

.booking-form-container {
    margin-top: 30px;
}

.booking-form-elements {
    padding: 40px 70px;
    background: #FFF;
    border-radius: 7px;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

}

.booking-form-elements ion-row {
    justify-content: flex-start;
    margin-top: 5px;
}

.date-label {
    line-height: 0px !important;
}

.materials-page-header ion-icon,
.order-container ion-icon {
    cursor: pointer;
}

.booking-form-elements ion-img {
    margin-top: 2px;
}

.booking-form-elements ion-input {
    border-radius: 8px;

    background: #FFF;
    width: 92%;
    height: 34px;
}

.payment-page-items-container {
    padding-top: 43px;
}

.payment-page-items-container .materials-page-header {
    padding-bottom: 43px;
}

.booking-form-elements ion-label,
.booking-form-elements ion-text {
    color: #484848;
    ;
    font-size: 21px;
    font-style: normal;

    line-height: normal;
}

.payment-items-head ion-col {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.payment-items-head ion-col:first-child,
.payment-items-head ion-col:nth-child(2) {
    justify-content: flex-start;
}

.payment-page-items ion-text {
    color: #484848;
    ;
    font-size: 21px;
    font-style: normal;

    line-height: normal;
}

.mat_info_icon {
    margin-top: -5px;
}

.payment-page-items {
    border-bottom: 1px solid #BEBEBE;
}

.items-row {
    padding: 10px 0px;
}

.payment-items-centerTxt {
    text-align: center;
}

.booking-listofdates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.booking-add-icon {
    font-size: 20px;
    color: var(--ion-bold-text-color);
    cursor: pointer;
}

.booking-paydonation {
    display: flex;
    align-items: flex-start;
    padding: 0px !important;
}

.confirm-Booking-btn {
    margin-top: 46px;
}

.booking-paydonation ion-checkbox {
    --border-radius: 10px !important;

}

.booking-paydonation ion-text {
    margin: 0px 10px;
}

.donAmt-field {
    display: flex;
    align-items: center;
}

.donAmt-field ion-input {
    max-width: 100px !important;
    margin-left: 10px;

}

.items-paymentBtn {
    padding-top: 35px;
}

.items-paymentBtn ion-col:nth-child(2) {
    text-align: right;
}

.payment-no-items {
    color: #404040 !important;
    ;
    font-size: 33px !important;
    font-style: normal;

    line-height: normal !important;
}

.payment-cost {
    color: #484848;
    ;
    font-size: 33px;
    font-style: normal;

    line-height: normal;
    margin-left: 50px;
}

.material-out-stock {
    color: lightcoral;
    font-weight: 100;
}

.cart-show-for-mobile p:not(:first-child) {
    margin: 10px 0px;
}

.deity-close-Icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.deity-close-Icon p:first-child {
    margin-top: 5px;
}

.materialiconcalendar {
    display: none;
}

.inputdate-container {
    position: relative;
    width: 100%;
}

.cart-show-for-mobile p {
    font-size: 18px;
    font-weight: 400;
    color: #3D3D3D;
    text-align: left !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    margin-bottom: 0px;

}

.payment-btn {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding-top: 56px;
}

.cart-show-for-mobile {
    display: none;
}

.items-row-mobile,
.custom-calender-icon {
    display: none;
}


@media only screen and (min-width:992px) and (max-width:1275px) {
    .material_img_container {
        width: 200px !important;
    }

    .view-cart-btn span {
        font-size: 20px;
    }

    .view-cart-btn {
        width: 140px;
    }
}

@media only screen and (min-width:1100px) {
    .orders-select-search-container {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media only screen and (min-width:992px) and (max-width:1300px) {
    .order-container {
        padding-right: 50px;
        padding-left: 50px;
    }

}

@media only screen and (min-width:992px) and (max-width:1100px) {
    .orders-select-search-container {
        padding-right: 20px;
    }

    .cart-items-txt {
        font-size: 17px;
    }
}

@media only screen and (max-width:1000px) {
    .booking-form-elements {
        padding: 40px 40px;
    }

    .booking-form-container {
        margin-top: 10px;
    }

    .booking-details-header {
        padding: 16px 40px 8px 40px;
    }

    .addtocart_btn {
        display: none;
    }

    .cartItems-header,
    .cart-total-view {
        margin-top: 10px;
    }

    .increment-decrement-element ion-input {
        font-size: 18px !important;
    }

    .order-container {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (max-width:1000px) {
    .cart-total-view {
        width: 262px;
        height: auto;
        padding-bottom: 30px;
        margin: 10px auto;
    }

    .cart-remove-element ion-text {
        display: none;
    }

    .increment-decrement-element span,
    .increment-decrement-element ion-icon {
        margin: 0px 0px 0px 2px;
    }

    .order-container {
        padding-left: 20px;
        padding-right: 20px;
        /* padding-top: 20px;
        padding-bottom: 20px; */
    }
}

@media only screen and (min-width:768px) and (max-width:910px) {
    .material_img_container {
        width: 210px !important;
    }
}

@media only screen and (max-width:920px) {

    .orders-deity-select,
    .orders-searchBar {
        width: 300px
    }

    .booking-form-elements ion-label,
    .booking-form-elements ion-text {
        font-size: 20px;

    }

}

@media only screen and (max-width:800px) {
    .details-quantity-element .increment-decrement-element {
        height: 40px !important;
        padding: 0px 25px;
    }

    .view-cart-btn {
        width: 140px;
    }
}

@media only screen and (max-width:768px) {
    .confirm-Booking-btn {
        text-align: center;
    }

    .cart-hide-for-mobile {
        display: none;
    }

    .book-details-forms ion-row ion-col:nth-child(3) ion-text {
        display: none;
    }

    .inputdate-container {
        width: 92%;
    }

    .payment-items-head {
        display: none;
    }

    .mobile-items {
        padding: 10px 40px;
    }

    .iconcalendarMat {
        display: block;
    }

    .listdatedefaultMat {
        display: none;
    }

    .items-row {
        display: none;
    }

    .custom-calender-icon {
        display: block;
        position: absolute;
        top: 18px;
        right: 8px;
        transform: translateY(-50%);
        z-index: 2;
    }

    .materialiconcalendar {
        display: block;
        width: 92% !important;
    }

    .payment-no-items,
    .payment-cost {
        font-size: 20px !important;
        margin-left: 0px;
    }

    .items-row-mobile {
        display: flex;
        border-bottom: 1px solid #BEBEBE;
        padding-bottom: 10px;
    }

    .booking-form-elements .items-row-mobile ion-label,
    .booking-form-elements .items-row-mobile ion-text {
        font-size: 18px;
    }

    .payment-page-items {
        border-bottom: none;
    }

    .cart-order-btn {
        padding-top: 0%;
    }

    .cart-show-for-mobile {
        display: flex;
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.08);
        padding: 10px;
    }

    .cartImg-view {
        width: 90%;
        height: 180px;
    }

    .cart-inc-dec {
        margin: 0;
    }

    .orders-deity-select,
    .orders-searchBar {
        width: 250px
    }

    .materialView-img-container {
        width: 100%;
        height: auto;
    }

    .material-image-view {
        width: 80%;
        height: auto;
        margin: 0 auto;
    }

    .material-details-container {
        padding-left: 0px;
    }
}

@media only screen and (max-width:576px) {
    .booking-form-elements {
        padding: 20px 10px;
    }

    .material-card-disable,
    .material-card {
        height: 180px;
    }

    .material-cards {
        padding: 10px 5px;
    }

    .cart-show-for-mobile .increment-decrement-element {
        height: 45px;
    }

    .material_img {
        width: 80% !important;
        height: 80% !important;
    }

    .material-name-size span,
    .material-stock {
        font-size: 15px;
    }

    .material-details-container .order-material-title {
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .details-quantity-element {
        margin-top: 10px;
    }

    .inputdate-container {
        width: 100%;
    }

    .details-available-stock {
        font-size: 18px;
        line-height: 20px;
    }

    .details-productName,
    .details-price,
    .details-description p {
        font-size: 20px;
        line-height: 25px;
    }

    .material-price {
        font-size: 18px;
    }

    .order-material-title {
        font-size: 18px !important;
        letter-spacing: 2px !important;
        margin-left: 5px !important;
    }

    .materials-page-header {
        padding-left: 5px;
        padding-right: 5px;
    }

    .mobile-items {
        padding: 10px 10px;
    }

    .booking-details-header h4 {
        font-size: 24px;
        padding: 0px;
    }

    .booking-details-header {
        padding: 12px 20px 6px 20px;
    }

    .booking-form-elements ion-label,
    .booking-form-elements ion-text {
        font-size: 18px;
    }

    .confirm-Booking-btn {
        text-align: center;
    }

    .orders-select-search-container {
        padding: 15px 0px;
    }

    .orders-select-search-container ion-select,
    .orders-select-search-container ion-searchbar {
        margin: 5px;
    }

    .increment-decrement-element *,
    .increment-decrement-element ion-input {
        font-size: 18px !important;
    }

    .view-cart-btn span {
        font-size: 16px !important;
    }



    .mat_main_cart_btn {
        width: 75px;
        height: 50px;
    }

    .mat_main_cart_btn ion-icon {
        font-size: 25px;
    }

    .mat_main_cart_btn {
        display: none;
    }

    .mat_info_icon {
        min-width: 25px !important;
        min-height: 30px !important;
    }

    .cart-show-for-mobile p {
        font-size: 16px;
    }

    .cartImg-view {
        width: 100%;
    }

    .cart-inc-dec {
        width: 125px;
        padding: 0px 10px;
    }

    .items-paymentBtn ion-col:nth-child(2),
    .items-paymentBtn ion-col:first-child {
        text-align: right;
    }

    .orders-deity-select,
    .orders-searchBar {
        width: 200px;
        padding: 0px 10px;
        font-size: 18px;
        height: 50px;
    }

    .orders-searchBar input {
        padding-right: 15px !important;
        padding-left: 25px !important;
    }

    .orders-searchBar .searchbar-search-icon {
        left: 0px;
    }

    .orders-searchBar .searchbar-clear-button {
        right: 0px;
    }
}

@media only screen and (max-width:450px) {

    .orders-deity-select,
    .orders-searchBar {
        width: 150px;
        padding: 0px 10px;
        font-size: 18px;
        height: 50px;
    }
}

@media only screen and (max-width:400px) {
    .custom-calender-icon {
        position: absolute;
        top: 17px;
        right: 8px;
        transform: translateY(-50%);
        z-index: 2;
    }

    .details-quantity-element .increment-decrement-element {
        padding: 0px 20px;
    }

    .view-cart-btn {
        width: 130px;
    }
}
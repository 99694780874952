.add_deity {
    margin-right: 4px;
}

.css tr:not(thead):hover {
    cursor: pointer;
    color: #00800096;
    background-color: #f5f5833d;
}

.css-1nmdiq5-menu {
    text-align: left;
}

.significant_css {
    min-height: 150px;
    /* Set minimum height */
    max-height: 100px;
    /* Set maximum height */
    resize: vertical;
    /* Allow vertical resizing */
    width: 100%;
    /* Set width to 100% */

    overflow-y: auto;
}

/* .jaZsat option {
    color: black !important;
} */
.stateSelect {
    height: 100px !important;
}

.hbotQl {
    white-space: break-spaces !important;
}



@media (prefers-color-scheme: dark) {
    /* tr:not(thead):nth-child(even) {
        background-color: #121212;
        color: white;
    }

    tr:not(thead):nth-child(odd) {
        background-color: #121212;
        color: white;

    } */

    .jaZsat option {
        color: white !important;
        background: black !important;
    }

    .fcZkth option {
        color: white !important;
        background: black !important;
    }
}

/* td {
    vertical-align: top;
} */

.time_off input[type="time"]:before {
    border: 1px solid #7db8ef;
    cursor: pointer;
    width: 100%;
    content: none;
    margin-left: 0px;
    margin-right: 0px;
}

.time_gram input[type="time"]:before {
    border: 1px solid #7db8ef;
    cursor: pointer;
    width: 100%;
    content: none;
    margin-left: 0px;
    margin-right: 0px;
}

@media (prefers-color-scheme : dark) {
    .time_off input[type="time"] {
        filter: invert(0);
    }

    .time_off input[type="date"] {
        filter: invert(0);
    }
}

.time_gram_mat input[type="time"]:before {
    border: 1px solid #7db8ef;
    cursor: pointer;
    width: 100%;
    content: none;
    margin-left: 10.5%;
    margin-right: 3%;
}

.time_gram1 input[type="time"]:before {
    content: 'HH:MM  AM/PM';
    width: 100%;
}

.time_gram3 input[type="time"]:before {
    content: 'HH:MM  AM/PM';
    width: 100%;
}

.time_gram2 input[type="time"]:before {
    content: 'HH:MM  AM/PM';
    width: 100%;
    background-color: white;
}

.threelineCls {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
    /* text-align: left; */
}

.deity_image {
    max-width: 100%;
    border: 1px solid;
    box-shadow: 0px 0px 6px #d1d1d1;
    border-color: #8080807a;
    min-width: 80px;
}



.image_chose {
    /* font-size: 1vw; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;




}

.mat_image_chose {
    /* font-size: 1vw; */
    cursor: pointer;
}

.button_style {
    --border-radius: 6px;
    text-transform: none;
}

.addGrammereceBody {
    padding: 2% 10%;
}

.button_style_int {
    --border-radius: 6px;
    text-transform: none;
}

.button_style1 {
    --border-radius: 6px;
    text-transform: none;
    --background: grey
}

.image_design {
    /* margin-right:11%; */
    box-shadow: 0px 0px 6px #d1d1d1;
    width: 150px;
    height: 150px;
}

.Col_1 {
    display: flex;
    justify-content: center;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.addgrmBtnCls {
    margin: 0;
    text-transform: capitalize;
}

.addMat-search {
    width: 180px;
    margin-top: 12px
}


@media (min-width: 900px) {
    .modal_dialog_width {
        margin-top: 10.5rem !important;
    }

    .deity_image {
        width: 14vw !important;
        border: 1px solid;
        box-shadow: 0px 0px 6px #d1d1d1;
        border-color: #8080807a;
        max-width: none !important;
    }

    .image_chose {
        /* font-size: 4vw !important; */
        cursor: pointer;
    }

    .image_design {
        /* margin-right: 44% !important;  */
        box-shadow: 0px 0px 6px #d1d1d1;
        width: 150px;
        height: 150px;
    }


    .ico_user {
        text-align: left !important;
        padding-top: 5%;
    }

    .modal_pad {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}






.modal_pad {
    padding-left: 10px;
    padding-right: 10px;
}

.color_item {
    --background: '#f7f7f7'

}

.ship-btns {
    width: 150px
}

.ship-btns-col1,
.ship-btns-col2 {
    text-align: center;
    padding: 0;
}

.modal_dialog_width {
    margin-top: 2.5rem;
}

.deityDescCls {
    max-height: 90px;
    overflow-y: auto;
}



.status-ship {
    font-size: 14px;
}

.offdefaulticons {
    text-align: center;
}

.officons {
    display: block;
    width: 100%;
}

.materialordericon {
    justify-content: center;
    padding: 0%;
}

.calaendariconorders {
    color: black !important;
}

@media only screen and (min-width:700px) {
    .offdefaulticons {
        display: block;
        text-align: center;
    }

    .officons {
        display: none;
    }

    .materialorderdefault {
        display: block;
    }

    .materialordericon {
        display: none;
    }

    .grammercedefaulticon {
        display: block;
    }

    .grammerceordericon {
        display: none;
    }
}

@media only screen and (max-width:700px) {
    .offdefaulticons {
        display: none;
    }

    .materialorderdefault {
        display: none;
    }

    .grammercedefaulticon {
        display: none;
    }

    .materialordericon {
        display: block;
        width: 150px;
    }

    .officons {
        display: block;
        width: 100%;
    }

    .grammerceordericon {
        display: block;
        width: 100%;
    }
}

@media only screen and (max-width:767px) {
    .ship-btns-col1 {
        text-align: right;
    }

    .ship-btns-col2 {
        text-align: left;
    }
}

.filebutton {
    font-size: 14px !important;
}

@media only screen and (max-width:460px) {
    .filebutton {
        font-size: 8px !important;
    }
}

@media only screen and (max-width:400px) {
    .ship-btns {
        width: 115px;
        font-size: 12px !important;
    }

    .status-ship {
        font-size: 12px;
    }

    .addMat-search {
        width: 130px;
        margin-top: 12px
    }
}

@media only screen and (max-width:350px) {
    .addgrmBtnCls {
        margin: 0;
        text-transform: capitalize;
        width: 130px
    }

    .button_style_int {
        width: 130px;
        --border-radius: 6px;
        text-transform: none;
    }

    .logo_loginpage {
        font-size: 25px;
    }
}

.manageorderscroll {
    height: 400px;
    overflow-y: auto;
}

.drawer {
    position: fixed;
    top: 5%;
    right: -400px;
    /* Initially hide the drawer off-screen */
    width: 400px;
    height: 0px;
    background-color: #FFFFFF;
    transition: right 0.3s ease-in-out;
    z-index: 100;
    opacity: 0;
}

.drawer.open {
    /* Show the drawer by setting the left position to 0 */
    right: 19%;
    opacity: 1;
    height: 590px;
    overflow-y: auto;
    border: 1px solid gray;
    padding: 10px;
}

.datatable_btn {
    text-transform: none;
    width: 118px
}

.datatable_btn_text {
    font-size: 12px;
    white-space: break-spaces;
}

@media only screen and (max-width:1030px) {
    .datatable_btn {
        text-transform: none;
        width: 100px;
    }

    .datatable_btn_text {
        font-size: 10px;
        white-space: break-spaces;
    }
}

.manageorderbtn {
    text-transform: none;
}

.status_gram {
    color: black;
}

@media (prefers-color-scheme : dark) {
    .status_gram {
        color: white;
    }
}

@media only screen and (max-width:1000px) {
    .manageorderbtn {
        text-transform: none;
        font-size: 10px;
    }
}

@media only screen and (max-width:900px) {
    .drawer {
        position: fixed;
        top: 5%;
        right: -300px;
        /* Initially hide the drawer off-screen */
        width: 300px;
        height: 0px;
        opacity: 0;

        background-color: #fffffa;
        transition: right 0.3s ease-in-out;
        z-index: 100;
    }

    .drawer.open {
        /* Show the drawer by setting the left position to 0 */
        right: 3%;
        height: 600px;
        opacity: 1;
        border: 1px solid gray;

    }

    .addGrammereceBody {
        padding: 2% 5%;
    }

}



.drawer-toggle {
    cursor: pointer;
    display: flex;
    padding: 10px;
}

.toggle-bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin-bottom: 5px;
}

.admgramerce-modal .modal-content {
    width: 45%;
    margin-left: 30%;
    margin-right: 20%;
}

.admalign_text {
    text-align: left;
}

.adm_label_color {
    color: gray !important;
}

.adm_action_div_adjust {
    padding: 0px 50px;
}

.adm_label_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}

.adm_textarea {
    height: auto;
    overflow-y: auto;
    text-align: justify;
}

.adm_image_contanier {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px
}

.adm_submit_btn_container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.adm_cancel_btn_container {
    display: flex;
    justify-content: start;
    align-items: center;
}

@media only screen and (max-width : 400px) {
    .adm_action_div_adjust {
        padding: 0px 0px;
    }
}

@media only screen and (max-width:700px) {
    .admgramerce-modal .modal-content {
        width: 60%;
        margin-left: 20%;
        margin-right: 15%;
    }
}

@media only screen and (max-width:560px) {
    .admgramerce-modal .modal-content {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media only screen and (max-width:490px) {
    .admgramerce-modal .modal-content {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media only screen and (max-width : 578px) {
    /* .admalign_text {
        text-align: center;
    } */
}

@media only screen and (max-width : 407px) {
    .adm_submit_btn_container {
        justify-content: center;
    }

    .adm_cancel_btn_container {
        justify-content: center;
    }
}

/*___________New userManagement css_____________*/
.admin_signup_modal ion-input {
    height: 35px;
    border-radius: 5px;
    width: 95% !important;
}

.admin-user-modal-header {
    padding: 0px !important;
}

.select_user_admin {
    height: 35px;
    /* border: 1px solid var(--border-color) !important; */
    border-radius: 5px;
    color: #737272 !important;
    width: 100% !important;
    background-color: white;
}



.admin_signup_modal .signUpNum {
    height: 35px;
    width: 95% !important;
    border-radius: 5px;
}

.admin_signup_modal ion-row {
    margin-top: 5px;
}

.admin_signup_modal .label_align {
    color: #404040;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}


/*temple css */

.temple_padding {
    padding-top: 0% !important;
}

.temple_menu_head_text {
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #FFFFFF; */
    cursor: pointer;
}

.temple_menu_head_text_selected {
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: var(--ion-color-primary);
}

.temple_menu_container {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-evenly;
    background-color: white;
    padding: 10px;
}

.temple_menu_align {
    display: none;
}

@media only screen and (max-width:767px) {
    .temple_menu_container {
        display: none;
    }

    .temple_menu_align {
        display: flex !important;
        align-items: center;
        justify-content: end;
        padding-right: 10px;
    }
}

.menu_temple_details_container {
    min-height: calc(100%-160px);
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 3%;

}

.temple_details_label {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.temple_textarea_input {
    border-color: #E4E4E4;
    background: white;
    border-radius: 5px;
    overflow-y: auto;
    height: 210px;
}

.temple_textarea_dress {
    border-color: #E4E4E4;
    background: white;
    border-radius: 5px;
    overflow-y: auto;
    height: 100px;
}

.temple_inputs {
    height: 38px;
    background-color: white;
    border-radius: 5px;
}

.temple_poojalist_inputs {
    height: 38px;
    background-color: white;
    border-radius: 5px;
}

.temple_poojalist_dropDown {
    height: 38px;
    background-color: white;
    border-radius: 5px;
    width: 100%;
}

.temple_video_add_icon {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:1170px) {}

@media only screen and (max-width:767px) {
    .temple_video_add_icon {
        display: flex;
        align-items: normal;
        margin-top: 22px;
    }
}

@media only screen and (max-width:522px) {
    .temple_video_add_icon {
        display: flex;
        align-items: normal;
        margin-top: 27px;
    }
}

@media only screen and (max-width:380px) {
    .temple_video_add_icon {
        display: flex;
        align-items: normal;
        margin-top: 32px;
    }
}


.temple_facilites_head_text {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.temple_offerings_textarea_input {
    border-color: #E4E4E4;
    background: white;
    border-radius: 5px;
    overflow-y: auto;
    height: 75px;
}

.temple_map_textarea_input {
    border-color: #E4E4E4;
    background: white;
    border-radius: 5px;
    overflow-y: auto;
    height: 80px;
}

.temple_poojalist_head {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    border: 0px !important;
}

.temple_poojalist_head_highlight {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    background: #EEF1F4;
    border: 0px !important;
}

.poojalist_input_container {
    background-color: #FFFFFF;
}

@media only screen and (max-width:990px) {
    .poojalist_input_container {
        background-color: #FFFFFF;
        margin-bottom: 15px;
    }
}

.poojaRow:hover {
    background-color: none !important;
}

.off_add_icon {
    cursor: pointer;
    color: var(--ion-color-primary);
    font-size: 30px;
    /* margin-top: 16px; */
}

.pooja_list_add_icon {
    cursor: pointer;
    color: var(--ion-color-primary);
    font-size: 30px;
}

.shop_add_icon {
    cursor: pointer;
    color: var(--ion-color-primary);
    /* background-color: var(--ion-color-primary); */
    font-size: 20px;
    border-radius: 50px;
}

.video_add_icon {
    cursor: pointer;
    color: var(--ion-color-primary);
    /* margin-top: 17%; */
    font-size: 30px;
}

.state_dropdown {
    z-index: 1100 !important;
}

/* offering ord manage */
.receipt {
    /* width: 108px; */
    /* overflow: hidden; */
    /* color: rgba(0, 0, 0, 0) */
    color: transparent;
    /* opacity: 0; */
}

.receipt_img_btn {
    --border-radius: 0px !important;
    --border-top-left-radius: 10px !important;
    --border-bottom-left-radius: 10px !important;
    --border-top-right-radius: 0px !important;
    --border-bottom-right-radius: 0px !important;
    font-size: 18px !important;
    height: 30px;
    margin-top: 0px;
    line-height: normal;
    font-weight: 400;
}

/* admin report page css */

.report_container_middle {
    padding-top: 2%;
    padding-left: 40px;
    padding-right: 20px;
    /* padding-bottom: 2%; */
    /* min-height: calc(100vh - 4px); */
}

.report_date_container {

    align-items: center;
}

.report_dates {
    height: 40px;
    margin-top: 10px;
}

.report_type_container {

    align-items: center;
    margin-top: 15px;
}

.checkbox_container {
    align-items: center;
    column-gap: 15px;
}

.report_radiogroup {
    display: flex;
    align-items: center;
    padding-left: 0px !important;
}

.report_radio_btn_container {
    align-items: center;
    display: flex;
    padding: 0px !important;
}

.report_radio_btn {
    margin-right: 15px;
    align-items: center;
}

.report_radio_btn2 {
    margin-left: 10px;
    margin-right: 15px;
    align-items: center;
}

.report_download_btn {
    justify-content: center;
    margin-top: 50px;
}

@media only screen and (max-width:1001px) {
    .report_container_middle {
        padding-top: 2%;
        padding-left: 40px !important;
        padding-right: 40px !important;
        /* padding-bottom: 2%; */
        /* min-height: calc(100vh - 4px); */
    }
}

@media only screen and (max-width:780px) {
    .report_date_container {
        column-gap: 0px;
        align-items: center;
    }

    .report_type_container {
        column-gap: 0px;
        align-items: center;
    }

    .report_container_middle {
        padding-top: 2%;
        padding-left: 10px !important;
        padding-right: 10px !important;
        /* padding-bottom: 2%; */
        /* min-height: calc(100vh - 4px); */
    }
}

@media only screen and (max-width:576px) {
    .report_radiogroup {
        display: grid;
        align-items: center;
        padding-left: 0px !important;
    }

    .report_radio_btn2 {
        margin-left: 0px;
        margin-right: 10px;
        align-items: center;
    }

    .report_radio_btn_container {
        align-items: center;
        display: flex;
        padding: 5px !important;
    }

    .report_radio_btn {
        margin-right: 10px;
        align-items: center;
    }
}

.district_container {
    padding-top: 10px !important;
}
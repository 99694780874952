.breadcrumb-container {
  background-color: #FFFFFF;
  padding: 10px 15px;
  border-radius: 8px;
  margin: 10px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
    0px 1px 3px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  height: fit-content;
}

.breadcrumb-nav {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212529;
}

.breadcrumb-disabled {
  color: #adb5bd;
  cursor: not-allowed;
  text-decoration: none;
}

.breadcrumb-link {
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  cursor: default;
}

.cursor_pointer {
  cursor: pointer !important;
}

.breadcrumb-link:hover {
  color: #0056b3;
}

.breadcrumb-active {
  font-weight: bold;
  color: #495057;
  cursor: default;
}


.breadcrumb-icon {
  font-size: 20px;
  color: #6c757d;
  margin-right: 10px;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: none !important;
}

.breadcrumb-chevron {
  font-size: 14px;
  color: #6c757d;
  margin: 0 5px;
}
.content_container {
    padding: 20px;
}

.card_container {
    background: #1B9FE6;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 10px;
    padding-bottom: 20px;
    border-radius: 15px;
}

.card_head_text {
    font-size: 40px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

.sub_content {
    font-size: 19px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

.icon_contact {
    color: #FFFFFF;
    font-size: 35px;
}

.phone_contact {
    font-size: 19px;
    font-weight: 400;
    line-height: 38px;
    color: #FFFFFF;
    text-decoration: underline;
    cursor: pointer;
}

.address_contact {
    font-size: 19px;
    font-weight: 400;
    line-height: 38px;
    color: #FFFFFF;
}

.logo_contact {
    color: #FFFFFF;
    font-size: 40px;
}

.contact_input {
    ;
    font-size: 19px;
    font-weight: 500;
    line-height: 15px;

    border-radius: 8px;
    height: 50px;
}

.message_contact {
    ;
    font-size: 19px;
    font-weight: 500;
    /* line-height: 15px; */

    border-radius: 8px;
    height: 150px;
    overflow-y: auto;
    padding-top: 5px;
    padding-left: 10px;
}

@media only screen and (max-width:767px) {
    .card_head_text {
        font-size: 20px !important;
    }

    .address_contact,
    .phone_contact {
        font-size: 15px;
    }

    .card_container {
        padding: 10px;
    }

    .content_container {
        padding: 10px;
    }
}
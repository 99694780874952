.but_pass {
    margin-right: 12%;
    text-transform: none;
}

.input_login {
    background: transparent;
    padding: 0px !important;
}

.footer_login {
    margin-top: 11%;
}

.pad_text {
    padding-left: 40px;
    padding-right: 40px;
}

.top_align {
    padding-top: 16%;
}

.foot_profile {
    padding-top: 8%;
}

@media (max-width: 900px) {
    .forgot_pass_css {
        /* margin-right: 44% !important; */
    }

    .footer_login {
        margin-top: 39% !important;
    }

    .but_pass {
        margin-right: 55% !important;
        text-transform: none;
    }

    .IonPage {
        margin-top: '140px';

    }

}

/*  *************** New Ui  for ATMAGRAM ***************        */

.page_container {
    width: 100%;
    height: 129%;
    background: white;
}

.img_container {
    padding: 0px;
    margin: 0px;
}

.logo_login {
    float: left;
    padding-top: 20px;
    padding-right: 5px;
}

.logo_class {
    width: 350px;
    align-items: right;
}


.login_container {
    padding: 40px;
    margin: 0;
    position: absolute;
    top: 48%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float: left;
}

.login_withmobile_container {
    padding: 40px;
    margin: 0;
    position: absolute;
    top: 48%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float: left;
}

.login_head {
    color: #222;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
}

.email_container {
    padding: 0px;
    margin-top: 50px;
}

.label_align {
    color: #484848;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


}

.select_user {
    color: #737272;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    width: 346px;
    height: 40px;
    flex-shrink: 0;

}

/* .pass_container {
    padding: 0px;
    margin-top: 25px;
} */

.forgot_pass_css {
    color: var(--ion-color-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;
    margin-left: 5px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484848;
    margin-left: -150px;

    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    ;
    margin-bottom: 0px;

}

.go_back_style {
    cursor: pointer;
    text-decoration: underline;
    color: var(--ion-color-primary);
}

.tool_img {
    background-image: url("/public/images/temp_login.png");
    background-size: 120% auto;
    height: 100%;
}

.signup_container {
    /* padding:40px; */
    padding: 0px 40px;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float: left;
}

.head_login {
    display: none;
}

/** NEW V2**/
.register_login_title {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px 20px;
}

@media only screen and (min-width:1900px) {

    /* .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: 100% auto;
        height: 120%;
       
      

    } */
    .login_top {
        align-items: left;
    }
}

@media only screen and (max-width:1900px) {

    /* .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: 101% auto;
        height: 100%;
       

    } */
    .login_top {
        align-items: center;
        justify-content: center;
        display: flex;

        /* height: calc(100vh - 130px); */

    }

    .email_container {

        margin-top: 0px;
    }

    .page_container {
        width: 100%;
        height: 100%;
        background: white;
    }

    .signup_container {

        /* padding: 40px; */
        padding: 0px 40px;

        margin: 0;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        float: none;
    }
}

.login_btn_container {
    justify-content: left;
    gap: 10px;
}

.signup_btn_container {
    padding-top: 10px;
}

/*
@media only screen and (max-width:750px) {
    .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: 101% auto;
        height: 150%;

    }
}

@media only screen and (max-width:700px) {
    .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: 101% auto;
        height: 150%;
      

    }
}

@media only screen and (max-width:600px) {
    .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: auto 101%;
        height: 70px;

    }
}

@media only screen and (max-width:400px) {
    .tool_img {
        background-image: url("/public/images/temp_login.png");
        background-size: auto 101%;
        height: 70px;

    } 
} */

@media only screen and (max-width:1200px) {
    .login_container {
        padding: 40px;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        float: left;
    }

    .login_withmobile_container {
        padding: 40px;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        float: left;
    }
}

@media only screen and (max-width:1000px) {
    .register_login_title {
        padding-top: calc(130px + env(safe-area-inset-top));
    }

    .signup_container {
        padding-top: 0px;
    }

    .login_container {
        padding-top: 50px;
    }

    .login_withmobile_container {
        /* padding-top: 130px; */
        padding-top: 50px;
    }

    .head_login {
        display: block;
    }

    .img_container {
        display: none;
    }

    .login_head {
        display: none;
    }

    .login_btn_container {
        justify-content: center;
    }

    .or_text_container {
        margin-left: 0px !important;
    }

    .signup_btn_container {
        padding-top: 10px;
        display: flex;
        justify-content: center;
    }

    .login_modal_btn_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width:882px) {
    .login_container {
        /* padding-top: 200px; */
        padding-top: 50px;
        top: 50%;
    }

    .login_withmobile_container {
        /* padding-top: 200px; */
        padding-top: 50px;
        top: 50%;
    }
}

@media only screen and (max-width:500px) {

    .login_container {
        /* padding-top: 200px; */
        padding-top: 50px;
        top: 0%;
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        float: none;
        position: relative;
    }

    .login_withmobile_container {
        padding-top: 50px;
        top: 0%;
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        float: none;
        position: relative;
    }
}

@media only screen and (max-width:550px) {
    .select_user {
        color: #737272;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        width: 270px;
        height: 40px;
        flex-shrink: 0;

    }

}

@media only screen and (max-width:300px) {
    .select_user {

        width: 220px;
        height: 40px;


    }

}

@media only screen and (max-width:320px) {

    .login_container {
        /* padding-top: 200px; */
        padding-top: 50px;
        top: 50%;
    }

    .login_withmobile_container {
        /* padding-top: 200px; */
        padding-top: 50px;
        top: 50%;
    }
}

.home_icon {
    color: var(--ion-color-primary);
    font-size: 22px;
}
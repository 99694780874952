.foot_profile {
    margin-top: 6%;
}

.foot_mobile {
    /* margin-top: 6%; */
    bottom: 0px;
    vertical-align: bottom;
    position: absolute;

}

.footer_parent {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-right: 20px;
    padding-bottom: 10px;
}

.foot_mobile_div {
    margin-top: 16px;
    bottom: 0px;
    vertical-align: bottom;
    position: fixed;
    height: 15px;
    max-height: 15px;
    vertical-align: bottom;
    background-color: var(--ion-color-primary);
}

.footer_margin {
    margin-top: 10px;
    padding-top: 10px;
}

/* Volunteer container for relative positioning */
.volunteer-container {
    position: relative;
    display: inline-block;
}

/* Submenu styling */
.volunteer-container .submenu {
    position: absolute;
    top: 30px;
    background-color: #ffffff;
    /* White background for the submenu */
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 0;
    width: 200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Soft shadow */
    z-index: 10;
    display: none;
    /* Hidden by default */
    opacity: 0;
    right: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.appVersionText {
    position: absolute;
    bottom: -5px;
    right: 5px;
    font-size: 10px;
    color: white !important
}

.initials-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 35px !important;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    color: #333 !important;
    font-weight: bold;
    font-size: 16px !important;
    cursor: pointer;
    margin-right: 10px;
}

/* Submenu items */
.submenu_item {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.2s ease;
}

/* Submenu item hover effect */
.submenu_item:hover {
    background-color: #f0f0f0;
    color: #007bff;
    /* Blue color on hover */
    border-radius: 4px;
}

/* Show submenu on hover */
.volunteer-container:hover .submenu {
    display: block;
    opacity: 1;
    transform: translateY(0);
}


@media (max-width: 900px) {
    .foot_profile {
        margin-top: 26% !important;
    }

    .foot_container {
        padding-left: 2% !important;
        padding-right: 3% !important;
    }
}

.sign_log_col {
    justify-content: end;
    align-items: center;
    display: flex;
}

.active-tab {
    color: #ffe875;
    text-decoration: none;
    font-size: 14px;
}

.inactive-tab {
    color: black;
    font-size: 14px;
    text-decoration: none;
}

.toolbar_head {
    padding: 10px 10px 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.foot_container {
    padding-left: 6%;
    padding-right: 6%;
}

@media (prefers-color-scheme: dark) {
    .active-tab {
        color: #ffe875;
        text-decoration: none;
        font-size: 15px;
    }

    .inactive-tab,
    .link_page {
        color: white;
        font-size: 15px;
        text-decoration: none;
    }

}

.logoImgCls {
    float: left;
}

.logoimgsize {
    height: 60px;
    width: auto;
}

.logoimgsize_for_ios {
    height: 40px;
    width: 200px;
}

.tool_img {
    background-image: url("/public/images/temp_login.png");
    /* background-repeat: no-repeat; */
    background-size: 100% auto;
    height: 100%;
}



.font_sign_up {
    font-size: 15px;
    font-weight: 700;
    line-height: 30.12px;
    color: var(--ion-bold-text-color);
}

.font_sign_up:hover {
    font-size: 15px;
    font-weight: 700;
    line-height: 30.12px;
    color: #FFFFFF;
}

.font_sign_up_active {
    font-size: 15px;
    font-weight: 700;
    line-height: 30.12px;
    color: #FFFFFF;
}

.font_sign_up_active:hover {
    font-size: 15px;
    font-weight: 700;
    line-height: 30.12px;
    color: #FFFFFF;
}

.slash_adjust {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:882px) {
    .logoimgsize {
        height: 60px;
        width: auto;
    }
}

@media only screen and (max-width:750px) {
    .logoimgsize {
        height: 50px !important;
        width: auto;
    }

    .footer_parent {
        display: block;
    }
}

@media only screen and (max-width:596px) {
    .logoimgsize {
        height: 40px !important;
        width: auto;
    }
}

@media only screen and (max-width:512px) {
    .logoimgsize {
        height: 40px !important;
        width: auto;
    }
}

@media only screen and (max-width:424px) {

    .logoImgCls {
        float: left;
    }
}

@media only screen and (max-width:390px) {
    .font_sign_up {
        font-size: 12px;
    }

    .sign_but {
        text-transform: none;
        --background: #fff !important;
        --color: darkslategrey !important;
        margin-left: 5%;
    }

    .slash_adjust {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 2%;
    }
}

@media only screen and (max-width:374px) {
    .font_sign_up {
        font-size: 12px;
    }

    .sign_but {
        text-transform: none;
        --background: #fff !important;
        --color: darkslategrey !important;
        margin-left: 5%;
    }

    .slash_adjust {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 2%;
    }
}

@media only screen and (max-width:375px) {
    .font_sign_up {
        font-size: 12px;
    }

    .sign_but {
        text-transform: none;
        --background: #fff !important;
        --color: darkslategrey !important;
        margin-left: 5%;
    }

    .slash_adjust {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 3%;
    }
}

.loginBtnMainCls {
    margin-right: 20px;
}

/* .rdt_Table {
    border: 1px solid rgba(0, 0, 0, .12);
    border-right: none;
}

.rdt_TableCell,
.rdt_TableCol {
    border-right: 1px solid rgba(0, 0, 0, .12);
    justify-content: center;
    font-size: 14px !important;
}

.rdt_TableRow {
    background: white !important;
}

.rdt_TableHeadRow {
    background: #f9f9f9 !important;
} */

/* ion-input input {
    padding: 5px 10px !important;
    height: 35px;
}

ion-input {
    border: 1px solid grey !important;
    margin-top: 0px !important;
}

ion-textarea,
ion-select {
    border: 1px solid gray !important;
    padding: 5px 10px !important;
    height: 35px;
}

ion-item ion-input input {
    padding: inherit !important;
    height: auto !important;
}

ion-item ion-input {
    border: none !important;
    border-radius: 0px !important;
}

ion-item ion-textarea,
ion-item ion-select {
    border: none !important;
    border-radius: 0px !important;
    height: auto !important;
} */

.popMenuListCls {
    padding: 0px;
}


/******************************* Home Header ******************************/

.head_sub_container {
    display: flex;
    background-color: transparent;
    padding: 3px 10px 0px 0%;
}

.home_head_sub_container {
    display: flex;
    padding: 70px 70px 0px 70px;
    background-color: transparent;
}

.home_head_background_img {
    background-image: url("/public/images/Atmagram1.1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    padding: 15px 0px;
    background-position: center bottom;
}

.head_background_img {
    background-image: url("/public/images/header.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    position: relative;
}

/* .home_head_sub_container1 {
    width: 100%;
    display: flex;
    float: right;
    padding: 120px 70px 0px 70px;
} */
.home_head_sub_container1 {
    width: 100%;
    display: flex;
    float: right;
    padding: 0px 20px 0px 20px;
    height: 100%;
    align-items: center;
}

.home_head_sub_container2 {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 10px 0px 0px 0px;
}

.home_budha_image {
    height: 584px;
    width: 679px;
    mix-blend-mode: hard-light;
    position: absolute;
    top: 253px
}

.home_budha_div {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 42%
}

/* .home_head_text {
    font-size: 60px;
    font-weight: 700px;
    line-height: 128.5px;
    color: #E6F1F3
} */
.home_head_text {
    font-size: 25px;
    font-weight: 700;
    color: #E6F1F3
}

.home_head_subtext {
    font-size: 15px;
    font-weight: 400;
    /* line-height: 36.5px; */
    color: #FFFFFF
}

.home_text_container {
    width: 100%;
    border: 1px solid #5b5b5c;
}

.home_text_container_parent {
    /* position: relative; */
    display: flex;
    height: fit-content;
    width: 50%;
}

.home_head_button {
    background-color: #576F9F !important;
    color: #FFFFFF;
    font-size: 25px !important;
    line-height: 50.2px;
    height: 82px;
    width: 312px;
    border-radius: 49px !important;
}

.home_head_button_container {
    padding-top: calc(100vh - 50vh);
}

@media only screen and (max-width : 1850px) {
    .home_head_button_container {
        padding-top: calc(100vh - 80vh);
    }
}

@media only screen and (max-width : 1500px) {
    .home_head_button_container {
        padding-top: calc(100vh - 92vh);
    }
}

@media only screen and (max-width : 1060px) {
    .home_head_button_container {
        padding-top: 0px;
    }
}

.head_container {
    z-index: 1;
    position: relative;
}

.head_bubble {
    width: 321px;
    height: 75px;
    border-radius: 60px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.head_bubble_container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    z-index: 2;
}

.head_text_container {
    display: flex;
    justify-content: center;
    /* padding-top: 10px; */
    padding-bottom: 10px;
}

.head_middle_text {
    font-size: 25px;
    font-weight: 700;
    /* line-height: 120.47px; */
    color: #E6F1F3;
}

.head_bubble_inside_comman_text {
    font-size: 20px;
    font-weight: 700;
    line-height: 54.21px;
    color: #6C6C6C;
}

.head_bubble_inside_page_text {
    font-size: 20px;
    font-weight: 700;
    line-height: 54.21px;
    color: var(--ion-bold-text-color);
}

.head_content_container {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.terms_label:hover {
    color: #576F9F !important;
}

.show_slide {
    display: none;

}

.menu_css {
    float: right;
    color: white;
    width: 100%;
    font-size: 27px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.head_menu_button {
    float: right;
    color: white !important;
    width: 100%;
    font-size: 27px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Side_Menu_Head {
    --background: var(--ion-color-primary);
    color: white;
}

.slider-container {
    /* width: 75%; */
    padding: 30px auto;
}

.home_head_sub_container_mobile {
    background-image: url("/public/images/Atmagram_Mobile_New_1.1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100dvh;
    /* Adjusts for mobile viewport changes */
    position: relative;
    background-position: center center;
    /* margin-top: calc(50px + env(safe-area-inset-top)); */
}



@media only screen and (max-width:1000px) {


    .logoImgCls {
        float: left;
    }

    .head_text_container {
        display: flex;
        margin: 10px 0px;
        /* margin-top: 10px; */
        align-items: center;
        justify-content: center;
        top: 50px;
        bottom: 0px;
        width: 100%;
        position: absolute;
        padding: 0px;
    }

    .head_middle_text {
        font-size: 22px;
        font-weight: 100;
        color: white;
        line-height: 0px;
    }

    .head_bubble_container {
        display: none;
    }

    .head_container {
        width: 100%;
        z-index: 1099;
    }

    .head_content_container {
        display: none;
    }

    .show_slide {
        display: block;
    }

    ion-menu {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1100;
    }


    .head_background_img {
        position: fixed;
        width: 100%;
        z-index: 1099;
        padding-bottom: 15px;
        height: 80px;

    }

    .home_head_background_img {
        height: auto;
        position: fixed;
        width: 100%;
        z-index: 1099;
    }

    .home_head_sub_container2 {
        gap: 0px;
    }

    .head_sub_container {}

}

@media only screen and (max-width:1160px) {
    .home_head_sub_container {
        padding: 20px;
    }

    /* .head_sub_container {
        padding: 20px;
    } */

    .head_content_container {
        gap: 20px;
    }
}

@media only screen and (max-width:1000px) {
    /* .head_bubble_container {
        display: none;
    } */

    .head_nav_bar_container {
        display: flex;
    }
}

@media only screen and (max-width:1360px) {

    .home_budha_div {
        position: relative;
    }

    .home_budha_image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
    }

    /* .home_head_sub_container1 {
        padding-top: 50px;
    } */
    .home_head_sub_container1 {
        padding-top: 0px;
    }

    .home_head_background_img {
        max-height: 100%;
    }
}

@media only screen and (max-width:1030px) {
    .home_budha_div {
        position: relative;
    }

    .home_budha_image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
    }

    /* .home_head_sub_container1 {
        padding-top: 50px;
    } */

    .home_head_sub_container1 {
        padding-top: 120px;
    }

    .home_head_background_img {
        max-height: 100%;
    }
}

/* @media only screen and (max-width:375px) {
    .head_sub_container{
        padding: 10px;
    }
} */

.padding_adjustment {
    padding-top: 0px;
}

@media only screen and (max-width : 767px) {
    .home_budha_div {
        display: none;
    }

    .home_text_container {
        width: 70%;
        height: fit-content;
    }

    .home_text_container_parent {
        width: 100% !important;
        justify-content: flex-end;
        margin-top: 40px;
    }

    .home_head_sub_container1 {
        height: 100%;
        /* align-items: flex-end; */
    }

    /* .home_head_sub_container_mobile {
        height: 95vh;
    } */
}

@media only screen and (max-width : 500px) {
    .padding_adjustment {
        padding: 0px !important;
    }

    .home_head_sub_container1 {
        display: block;
    }

}
.sel {
  margin-left: 10%;
  font-weight: bold;
}

.text {
  font-size: 80%;
}

.temple_list {
  border-radius: 20px;
  background: #F3F3F3;
  margin: 10px 10px;
  padding-left: 20px;
  color: #404040;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="date"] {
  cursor: pointer;
}

.Input {

  border: 0.2px solid #DCDCDC;

}

.IonInput {
  border: 1px solid #CCCCCC;
  width: 70%;
  height: 60%;
}

.placeholder1 {
  margin: 5px 0px;

  width: 100%;
  height: 50%;

}

.bou {
  border: 1px solid #CCCCCC;
  width: 90%;
  height: 100%;
}

.bou_drop {
  border: 1px solid #CCCCCC;
  width: 90%;

}

.textsize {
  font-size: 14px;
  margin-left: 30%;
  font-weight: bolder;
}

.Button_R {
  margin-top: 3%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
}

.grid {
  width: 100%;
  /* margin-top: 2%; */
  padding: 25px;
  height: 100%;
}

.grid_table_head {
  font-weight: bold;
  border: 0px !important;
  text-align: center;
  cursor: auto !important;
}



.To {

  margin-left: 10%;
  margin-right: 5%;
  margin-top: 2%;

}

.Too {
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 1%;
}

.or {
  margin-left: 12%;

}

.sa {
  height: 50% !important;
}

.sa1 {

  margin-top: 2%;
}

.sa2 {

  margin-top: 2%;
  margin-left: 0%;
}

.sal {
  /* margin-left: 40%; */
  font-weight: bold;
  /* text-align: center; */
}

.oo {
  border-left: 1px solid #CDCDCD;


}

.table {
  width: 100%;
  margin-top: 4%;
}

.description_text {
  display: -webkit-box;
  height: 20px;
  width: 95%;
  margin-left: 2%;
  margin-right: 2%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title_text {
  display: -webkit-box;
  font-weight: bold;


  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tab {
  height: 100%;
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.tab1 {
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.tn {
  text-align: center;
  width: 100%;
}

.my-custom-interface .brown-option {
  --color: #5e3e2c;
  --color-hover: #362419;
  --width: 300px;
}

.popover-content {
  width: 100%
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

.Login_modal {
  --border-width: 0px 0 0px 0;
  --inner-border-width: 0;
  --show-full-highlight: 0;
  --show-inset-highlight: 0;
  height: 87%;
  width: 100%;
  border: 1px solid #576F9F;
}

.sign_label {
  font-size: 12px !important;
}

.sign_label_user {
  font-size: 20px !important;
}

.row_log {
  margin-bottom: 9px !important;
}

.Signup_modal {
  --border-width: 0px 0 0px 0;
  --inner-border-width: 0;
  --show-full-highlight: 0;
  --show-inset-highlight: 0;
  height: 100%;
  width: 65%;
  border: 1px solid #576F9F;
}

.myCartPhone {
  border: none !important;
  background-color: white;
  color: black;
  cursor: default;
  height: auto !important;
  padding: 0px !important;
}

.myCartPhone .PhoneInputInput {
  background-color: white !important;
  cursor: default;
}

.myCartPhone .PhoneInputCountrySelect,
.profNum .PhoneInputCountrySelect {
  pointer-events: none;
}

.myCartPhone .PhoneInputCountrySelectArrow {
  display: none;
}

.padding_pay {
  padding-top: 2%;
  user-select: none;
}

@media (prefers-color-scheme: dark) {
  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 65%;
    border: 1px solid #576F9F;
    background-color: black;
  }

  .myCartPhone {
    border: none !important;
    color: black;
  }

  .myCartPhone .PhoneInputInput {
    background-color: white !important;
    cursor: default;

  }

  .adminSignUp {
    background-color: black;
  }

  .Signup_modal .PhoneInput {
    color: white;
    background-color: black;
  }

  .Signup_modal .PhoneInputInput {
    background-color: black;
  }

  .adminSignUp .PhoneInput {
    color: white;
    background-color: black;
  }

  .adminSignUp .PhoneInputInput {
    background-color: black;
  }
}

.row_sign {
  margin-bottom: 9px !important;
  margin-left: 20%;
}

@media only screen and (max-width:1000px) {
  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 60%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 30%;
  }
}

@media only screen and (max-width:891px) {
  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
    margin-left: 35%;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (max-width:791px) {
  .padding_pay {
    padding-top: 5%;
    user-select: none;
  }

  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
    margin-left: 30%;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (max-width:600px) {
  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
    margin-left: 20%;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (max-width:425px) {
  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
    margin-left: 10%;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 2%;
    margin-right: 2%;
  }

  .temple_list {
    border-radius: 20px;
    background: #F3F3F3;
    margin: 5px 5px;
    padding-left: 20px;
    color: #404040;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    cursor: pointer;
  }
}

@media only screen and (max-width:325px) {
  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media only screen and (max-width:400px) {
  .textsize {
    font-size: 14px;
    margin-left: 2%;
    font-weight: bolder;
  }
}


.zoom {


  transition: transform .2s;
  width: 250px;
  height: 300px;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(2.5);
  /* IE 9 */
  -webkit-transform: scale(2);
  /* Safari 3-8 */
  transform: scale(2);
}


.chevronForward {
  margin-left: 10px
}

.chervonIcon {
  position: absolute;
  top: 11px;
  right: 0px;
  left: 41px;
  bottom: 0px;
  font-size: 21px;
}

.chervonBackIcon {
  position: absolute;
  top: 11px;
  right: 37px;
  left: 0px;
  bottom: 0px;
  font-size: 21px;
}


.btn_enable {
  color: "black";
  cursor: "context-menu";
  text-align: "center";
  margin-top: "10%"

}

.btn_disable {
  color: "black";
  cursor: not-allowed;
  text-align: "center";
  margin-top: "10%"
}

@media only screen and (max-width:600px) {
  .Login_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 87%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_log {
    margin-bottom: 9px !important;
    margin-left: 20%;
  }

  .Signup_modal {
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #576F9F;
  }

  .row_sign {
    margin-bottom: 9px !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .chevronForward {
    margin-left: 0px
  }

  .chevronBack {
    margin-left: 3px
  }

  /* .chervonIcon {
    position: absolute;
    top: 10;
    right: 0px;
    left: 31px;
    bottom: 0px
  } */
}

/* 
******** new ui css  ********* */

.img_class {
  width: 123px;
  height: 161px;
  flex-shrink: 0;
}

.deity_style {
  color: #222;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
}

.temple_container {
  /* padding-top: 130px; */
  background: white;
}

.history_style {
  color: #404040;
  white-space: pre-wrap;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: justify;
}

.header_style {
  color: var(--ion-bold-text-color);

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}

.temple_menu {
  padding-left: 100px;
  padding-top: 50px;

}

.temple_back {

  padding-top: 85px;
  padding-bottom: 0px;
  padding-left: 50px;
}

.facility_text {
  color: #484848;
  ;
  font-size: 21px;
  font-style: normal;

  line-height: 30px;
  align-self: center;
}

.tick {
  width: 20px;
  height: 20px;
  color: var(--ion-bold-text-color);


}

.tick_container {
  top: 5px;
  display: flex;
  justify-content: center;




}

.mat_tick_contain {
  display: flex;
  justify-content: center;

}

.offering_head {
  color: #222;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
}

.offering_style {
  color: #484848;
  ;
  font-size: 21px;
  font-style: normal;

  line-height: 30px !important;
  padding-top: 15px;
}

.facility_container {
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

  margin: 50px 150px;

}

.schedule_container {
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

  margin: 50px 50px;

}

.video_link {
  color: #484848;
  ;
  font-size: 18px;
  font-style: normal;

  line-height: 33px;
  padding-top: 20px;
  padding-left: 50px;
}

.live_link {
  color: #13ACFF;
  ;
  font-size: 18px;
  font-style: normal;


  padding-top: 0px;
  padding-left: 50px;
  line-height: 33px;
  /* 183.333% */

}

.parent {
  position: relative;

}

.child {
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 20px;


}

.map_share {
  height: 41px !important;
}

.share_icon {
  color: #FFF;
  width: 29px;
  height: 29px;
  margin-left: 10px;
}

.select_text {
  color: var(--ion-bold-text-color);

  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}

.deity_text_search {

  font-size: 27px;
  color: var(--ion-color-primary);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.search_label {
  color: #484848;
  ;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.search_text {
  border-radius: 8px;


  height: 45px;
  width: 90%;
}

.clear_all {

  font-size: 20px !important;

  padding-right: 20px;


  --background: #FFF !important;
  color: #404040 !important;



}

.search_btn {
  font-size: 20px !important;
  padding-left: 30px;
  padding-right: 50px;

}

.temple_bg {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;


  background-repeat: no-repeat;
  /* background-image:  url("/public/images/temple_serch_bg.png"); */
}

.temple_budha_bg {
  width: 95%;
  height: 100%;
  background-image: url("/public/images/sankaracharya.png");
  position: absolute;
  background-repeat: no-repeat;
  bottom: 0;

  text-align: center;
}

@media only screen and (max-width:800px) {
  .temple_bg {
    position: unset;
    width: 100%;
    /* height: 100%; */
    padding-left: 0px;
    justify-content: center;
    /* justify-content: center; */
    align-items: center;
    display: flex;
    background-repeat: no-repeat;
    background-image: none;
  }

  .temple_budha_bg {
    /* width: 90%; */
    display: none;
  }
}

.multi-color-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  background-color: var(--ion-color-primary);
}

.no_result {
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  color: black;
}

@media only screen and (max-width:800px) {
  .facility_container {
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

    margin: 30px 30px;

  }

}

@media only screen and (max-width:900px) {
  .facility_container {
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

    margin: 20px 20px;

  }

  .grid {
    width: 100%;

    padding: 10px;
    height: 100%;
  }

  .facility_text {
    color: #484848;
    ;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    align-self: center;
  }

  .schedule_container {
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

    margin: 20px;

  }

  .clear_all {

    font-size: 20px !important;

    padding-right: 0px;


    --background: #FFF !important;
    color: #404040 !important;



  }

  .search_btn {
    font-size: 20px !important;
    padding-left: 30px;
    padding-right: 0px;

  }
}

@media only screen and (max-width:576px) {
  .textsize {
    font-size: 14px;
    margin-left: 0%;
    font-weight: bolder;
  }

  .header_style {
    color: var(--ion-bold-text-color);

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: unset;
    letter-spacing: 2px;
  }

  .history_style {
    white-space: pre-wrap;

    font-size: 16px;

    line-height: 30px;

  }

  .offering_head {


    font-size: 25px;

    line-height: 30px;
  }

  .offering_style {
    font-size: 18px;
  }
}

@media only screen and (max-width:882px) {
  /* .temple_container {
    padding-top: calc(100px + env(safe-area-inset-top));
  } */
}

.custom_alert {
  user-select: none;

  .alert-button {
    user-select: none;
  }
}

.summary_textarea {
  min-height: 500px !important;
}
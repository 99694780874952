.project-card {
    /* min-height: 50% !important; */
    width: 85% !important;
    /* margin: 0% 5%; */
    border: 1px solid gray;
}

.progress {
    --background: "red";
}

.btns {
    width: 100%;
    padding: 5% 0%;
    --background: white;
}

.purpose_text {
    display: -webkit-box;
    height: 50px;
    width: 95%;
    margin-left: 2%;
    margin-right: 2%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* padding: 2%; */
}

.status-ship {
    font-size: 14px;
}

@media only screen and (max-width:920px) {
    .project-card {
        text-align: center;
        width: 80% !important;
        margin: 0 5%;
    }
}

@media only screen and (max-width:800px) {
    .project-card {
        text-align: center;
        width: 80% !important;
        margin: 0 10%;
    }
}

@media only screen and (max-width:500px) {
    .project-card {
        text-align: center;
        width: 90% !important;
        margin: 0 5%;
    }
}

@media only screen and (max-width:400px) {
    .status-ship {
        font-size: 12px;
    }
}

@media (prefers-color-scheme: dark) {
    .status-ship input[type="date"]::-webkit-calendar-picker-indicator {
        font-size: 12px;
        filter: invert(1);
    }

    .status-ship input[type="time"]::-webkit-calendar-picker-indicator {
        font-size: 12px;
        filter: invert(1);
    }
}


/*______New UI______*/
.project_container {
    background: linear-gradient(180deg, #252D3E 0%, #425885 100%) !important;
    padding-bottom: 4%;
}

.project-slider-container {
    padding: 0% 8%;
    text-align: center;
}

.page-title {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    color: var(--ion-bold-text-color);
    line-height: normal;
    letter-spacing: 2px;
    text-align: left;
}

.project_cards {
    max-width: 479px;
    /* height: 319px; */
    background: #EEF4FF;
    border-radius: 15px;
    box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.23);
    padding: 4% 0%;
    text-align: left;
}

.project-infoIcon {
    max-width: 24px;
    max-height: 24px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    padding: 0% 5%;
    width: 100%;
    color: #404040;
    line-height: 64px;

}



.card-title {
    font-size: 23px;
    width: 90%;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-content {
    font-size: 11px;
    font-weight: 400;
    color: #404040;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0% 5%;
    height: 9em;
    width: 100%;

}

.project-modal-header {
    padding: 10px 40px 10px 40px !important;
}

.project_container .no-materials {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 21px;
    width: 100%;
    height: 100%;
}

.card-icons {
    width: 32px;
    height: 32px;
    margin: 0 auto;
}


.progressNcost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
    padding: 0% 4%;
    width: 100%;
}

.progressNcost p {
    margin: 0;
}

.card-icons-parent {
    text-align: center;
}

.days-likes {
    font-size: 14px;
    font-weight: 400;
    color: #848484;
    margin: 5% 0%;
}

.card-progressBar {
    width: 167px;
    height: 10px;
    border-radius: 15px;
    background: #FFF;
}

.project-rupees {
    font-size: 23px;
    font-weight: 700;
    font-style: normal;
    color: var(--ion-bold-text-color);
}

.donate-btn {
    height: 41px;
    width: 143px;
    margin: 0%;
}

.dontion-btn-txt {
    font-size: 17px;
    font-weight: 700;
    color: #FFF;

}

.shareIcon-element {
    width: 41px;
    height: 41px;
    background: var(--ion-color-primary);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
}

.share-icon-project {
    width: 21px;
    height: 21px;
    color: #FFFFFF;
    cursor: pointer;
}

.donate-share {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 3%;
}

.slider-element {
    width: 90%;
    margin: 0% auto;
}

.slick_nxt_project {
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 100%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #576F9F;
}

.slick_prev_project {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    right: 100%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #576F9F;
    padding: 0;

}

.arrow-forward-back {
    width: 53px;
    height: 53px;

}




.project-contribute-inputElement {
    display: flex;
}

.contribute-info-icon {
    margin-left: 23px;
    margin-top: 5px;
}

@media only screen and (min-width:1100px) and (max-width:1300px) {
    .project_cards {
        width: 410px !important;
    }
}

@media only screen and (max-width:675px) {
    .slider-element {
        width: 100%;
        margin: 0% auto;
    }

    .contribute-info-icon {
        margin-left: 2px;
        margin-top: 5px;
    }

    .slick_nxt_project,
    .slick_prev_project ion-img {
        width: 43px;
        height: 43px;

    }
}

@media only screen and (max-width:577px) {

    .project-infoIcon,
    .card-icons,
    .share-icon-project {
        width: 20px;
        height: 20px;
        color: #FFFFFF;
        cursor: pointer;
    }

    .arrow-forward-back {
        width: 33px;
        height: 33px;

    }

    .card-title,
    .project-rupees,
    .dontion-btn-txt {
        font-size: 16px;
    }

    .days-likes {
        font-size: 12px;

    }

    .progressNcost {
        justify-content: space-around;
    }

    .card-progressBar {
        width: 120px;
    }

    .slider-element .slick-list {
        margin: 0% 2%;
    }

    .donate-share {
        display: block;
    }

    .donate-btn {
        height: 31px;
        width: 120px;
        margin: 0%;
    }

    .shareIcon-element {
        width: 31px;
        height: 32px;
    }

    .shareIcon-mobile {
        display: flex;
        justify-content: center;
    }

    .card-header {
        line-height: 30px;
    }

    .slick_nxt_project,
    .slick_prev_project ion-img {
        width: 33px;
        height: 33px;

    }
}

@media only screen and (max-width:390px) {
    .card-progressBar {
        width: 70px;
    }
}
.text_decor {
  text-decoration: none;
  color: black;
  line-height: 18px;
}

.text_decor :hover {
  color: black
}

.ion_titles_user {
  font-size: 2.7rem;
  padding: 0px 9px;
  line-height: 148%;
  margin-top: 0px;
}

.profileInput {
  height: 70px !important;
}

.custom-select .css-3w2yfm-ValueContainer {

  overflow-y: auto;
}

/* @media (prefers-color-scheme: light) { */

.custom-select .css-1pahdxg-control {
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.custom-select .css-qbdosj-Input {
  color: #737272 !important;
  ;


  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-166bipr-Input {
  color: #737272 !important;
  ;


  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-19bb58m {
  color: #737272 !important;
  ;


  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-f2xgbl-control {

  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.custom-select .css-1807sfa-control {
  border: 1px solid var(--border-color);
  border-radius: 8px;

}

.custom-select .css-1nmdiq5-menu {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 90%;


}

.custom-select .css-d7l1ni-option:hover {
  background-color: rgb(233, 233, 234);
}

.custom-select .custom-select-dark .css-1nmdiq5-menu .option-item:hover {
  outline: white;
  background-color: rgb(233, 233, 234);
  color: #737272 !important;
  ;


  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select {
  z-index: 1001;
  color: #737272 !important;
  ;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1001;
}



.custom-select .css-1dimb5e-singleValue {
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select-dark .css-1dimb5e-singleValue {
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select-dark {
  color: #737272 !important;
  ;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-3w2yfm-ValueContainer {
  height: 38px;
  overflow-y: auto;
}

.custom-select-dark .css-1nmdiq5-menu {
  width: 90%;

}

.custom-select .css-bnzhvk-control {
  --border-radius: 8px !important;

}

.custom-select .css-10ic4bg-control,
.css-bnzhvk-control {

  color: #737272 !important;
  ;
  --border-radius: 8px !important;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-10ic4bg-control .css-166bipr-Input {
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select-dark .css-10ic4bg-control .css-166bipr-Input {
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select-dark .css-10ic4bg-control {

  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-select .css-d7azi3-control {}

.select-user {}

.custom-select-dark .css-1jgb89e-control {

  border: 1px solid var(--border-color) !important;
  border-radius: 8px !important;
  color: #737272 !important;

  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.css-l1dgy3-control {
  border: 1px solid var(--border-color) !important;
  border-radius: 8px !important;
}

/* .custom-select-dark .css-d7l1ni-option {

  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.custom-select .css-1jgb89e-control {

  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: #737272 !important;
  ;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* } */
/* @media (prefers-color-scheme: dark) {

  .custom-select .css-1pahdxg-control {
    border-color: #ffffff;
  }

  .custom-select .css-qbdosj-Input {
    color: white;
  }

  .custom-select .css-166bipr-Input {
    color: white;
  }

  .custom-select .css-19bb58m {
    color: white;
  }

  .custom-select .css-f2xgbl-control {
    background-color: #121212;
    border-color: #979797;
  }

  .custom-select .css-1807sfa-control {
    border-color: #979797;
    background-color: #121212;
  }

  .custom-select .css-1nmdiq5-menu {
    border-color: #979797;
    
    background-color: #121212;
  }

  .custom-select .css-d7l1ni-option:hover {
    background-color: rgb(233, 233, 234);
  }

  .custom-select .custom-select-dark .css-1nmdiq5-menu .option-item:hover {
    outline: white;
    background-color: rgb(233, 233, 234);
    color: white;
  }

  .custom-select {
    z-index: 1001;
    color: white;
  }

  .custom-select .css-1dimb5e-singleValue {
    color: white;
  }

  .custom-select-dark .css-1dimb5e-singleValue {
    color: white;
  }

  .custom-select-dark {
    color: white;
  }

  .custom-select .css-3w2yfm-ValueContainer {
    height: 38px;
    overflow-y: auto;
  }

  .custom-select-dark .css-1nmdiq5-menu {
   
    background-color: #121212;
  }

  .custom-select .css-bnzhvk-control {
    background-color: #121212;
  }

  .custom-select .css-10ic4bg-control,
  .css-bnzhvk-control {
    background-color: #121212;
    color: white;
  }

  .custom-select .css-10ic4bg-control .css-166bipr-Input {
    color: white;
  }

  .custom-select-dark .css-10ic4bg-control .css-166bipr-Input {
    color: white;
  }

  .custom-select-dark .css-10ic4bg-control {
    background-color: #121212;
    color: white;
  }

  .custom-select .css-d7azi3-control {
    background-color: #121212 !important;
  }

  .select-user {
    background-color: #121212 !important;
  }

  .custom-select-dark .css-1jgb89e-control {
    background-color: #121212;
    border: 1px solid white;
    color: white;
  }

  .custom-select-dark .css-d7l1ni-option {
    background-color: white;
    border: 1px solid #121212;
    color: #121212;
  }

  .custom-select .css-1jgb89e-control {
    background-color: #121212;
    border: 1px solid white;
    color: white;
  }

} */

.custom-select .css-1nmdiq5-menu {
  /* width: 90%; */
}

.popover_head {
  background: white;
  margin-right: 100px;
}

.menu_css {
  float: right;
  color: white;
  width: 40px !important;
  font-size: 27px;
  align-items: center;
  justify-content: center;
  /* padding-top: 38px !important; */
}

.space-for-iOS {
  height: 10px;
  margin-top: 8px;
}

@media only screen and (max-width:375px) {
  .menu_css {
    float: right;
    color: white;
    width: 40px !important;
    font-size: 27px;
    align-items: center;
    justify-content: center;
  }
}

.pop_menu_css {
  float: left;
  color: yellowgreen;
  width: 40px !important;
  font-size: 27px;
  padding-right: 7px;
  padding-top: 5px !important;
}

.filterinput {
  text-align: left;
  max-width: 200px !important;
  border: 1px solid #d1d1d1 !important;
  color: black;
  border-radius: 3px;
  height: 30px;
}

@media only screen and (max-width:340px) {
  .filterinput {
    text-align: left;
    max-width: 150px !important;
    border: 1px solid #d1d1d1 !important;
    border-radius: 3px;
    height: 30px;
  }
}

@media only screen and (max-width:768px) {
  .hidecolon {
    display: none;
  }

  .mobileNumberText {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .newMobileInput {
    width: 100%;
  }
}

.textDes {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dApqnJ {
  position: relative;
  width: 100%;
  display: table;
  border: 1px solid rgba(0, 0, 0, .12);
}

.glyQNg {
  border-right: 1px solid rgba(0, 0, 0, .12);
  justify-content: center;
}

.bQdYbR {
  border-right: 1px solid rgba(0, 0, 0, .12);
  justify-content: center;
}

@media (max-width: 900px) {
  .dividers {
    min-height: 1px !important;
  }

  .menu_css {
    float: right;
    color: white;
    width: 110%;
    font-size: 27px;
    align-items: center;
    justify-content: center;
  }

  .ion_titles_user {
    font-size: 2.1rem !important;
    padding: 0px 9px !important;
    line-height: 148% !important;
    margin-top: 6px !important;
  }

  .footer_logins {
    margin-top: 122% !important;
  }

  .top_profile {
    margin-top: 10% !important;
  }

  .footer_change_pass {
    margin-top: 34% !important;
  }

  .modal_sign {
    --height: 90% !important;
    --width: 84% !important;
    --border-radius: 10px;
  }

  .item_details {
    border: 1px solid !important;
    width: 100% !important;
    border-radius: 6px !important;
    margin-bottom: 20px !important;
    margin-left: 13px !important;
    --border-width: 0px 0 0px 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    border-color: darkgrey !important;
    margin-right: 14px !important;
    --background: #f7f7f7;
  }

  .overflow_modal {
    overflow-y: auto !important;
    margin-top: 10%;
  }

  .icon_info {
    font-size: 20px;
    margin-top: 3px !important;
    color: #576F9F;
    margin-left: 5px;
    cursor: pointer
  }

  .modal_logout {
    --height: auto !important;
    --width: 84% !important;
    --border-radius: 10px;
  }

  .container_middles {
    text-align: center;
    padding-top: 20%;
    padding-left: 7%;
    padding-right: 8%;
  }

  .container_middle {
    text-align: center;
    padding-top: 20%;
    padding-left: 2% !important;
    padding-right: 3% !important;
    font-size: 14px;
  }

  .deity_gods {
    text-align: -webkit-center;
    max-width: 65%;
    height: 125px;
  }

  .deity_gods_col {
    text-align: -webkit-center;
    margin-bottom: 11%;
  }

  .font_size {
    font-size: 1.8rem !important;
  }
}

.border_menu {
  --inner-border-width: 0 0 0px 0 !important;
  font-size: 14px;
}

.footer_logins {
  margin-top: 27%;
}

.dividers {
  min-height: 15px;
}

.top_profile {
  margin-top: 4%;
}

.read_only {
  --background: #cccaca;
}

.footer_change_pass {
  margin-top: 7%;
}

.modal_sign {
  --height: 72%;
  --width: 600px;
  --border-radius: 10px;
}

.modal_adhar {
  --height: 80%;
  --width: 600px;
  --border-radius: 10px;
}

.sign_modal_bot {
  text-align: center;
  margin-top: 9%;
}

th {
  padding: 10px !important;
}

td {
  border-bottom: 1px solid #dbdbdb !important;
  padding: 8px !important;
  text-align: left;
  cursor: pointer;
}

.css tr:not(thead):hover {
  cursor: pointer;
  color: #00800096;
  background-color: #f5f5833d;
}

.css th,
tr:hover {
  background-color: white;
  color: black;
  cursor: auto !important;
}

@media only screen and (min-width:900px) {
  .container_middle {
    text-align: center;
    padding-top: 200px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 200px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }
}

@media only screen and (max-width:900px) {
  .container_middle {
    text-align: center;
    padding-top: 100px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 120px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }
}

@media only screen and (max-width:800px) {
  .container_middle {
    text-align: center;
    padding-top: 100px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 100px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }
}




.overflow {
  overflow-x: auto;
}

.details_modal {
  float: right;
  margin-top: -8px;
  font-size: 31px;
  margin-right: 15px;
  cursor: pointer;
}

.item_details {
  border: 1px solid;
  width: 45%;
  border-radius: 6px;
  margin-left: 11px;
  --border-width: 0px 0 0px 0;
  --inner-border-width: 0;
  --show-full-highlight: 0;
  --show-inset-highlight: 0;
  border-color: darkgrey;
  margin-right: 15px;
  margin-bottom: 10px;
  --background: #f7f7f7;
}

.button_modal {
  --border-radius: 7px;
  margin-right: 10px !important;
}

.overflow_modal {
  overflow-y: auto;
  margin-top: 10%;
}

.link_page:hover {
  color: green;
  font-size: 15px;
}

.link_page {
  color: black;
  font-size: 15px;
  text-decoration: none;
}

.font_size {
  font-size: 2.2rem;
}

.modal_logout {
  --height: auto !important;
  --width: 600px;
  --border-radius: 10px;
}

.deity_gods_col {
  text-align: -webkit-center;
  margin-bottom: 3%;
}

.point {
  cursor: pointer;
  font-size: 25px;
}

.deity_gods {
  text-align: -webkit-center;
  max-width: 50%;
  height: 100px;
  cursor: pointer;
}

.icon_info {
  font-size: 20px;
  margin-top: 2px;
  color: #576F9F;
  margin-left: 5px;
  cursor: pointer
}

.info_modal {
  --border-radius: 12px
}

.input_css {
  --padding-top: 2px !important
}

.label_css {
  margin-top: -9px !important;
}

.label-floating {
  color: #979797 !important;
}

.root_header {
  height: 10px;
  max-height: 10px;
}

@media only screen and (max-width:1000px) {
  .show_slide {
    display: block;

  }

  .show_pop {
    display: none;

  }

  ion-menu {
    opacity: 1;
  }


}

@media only screen and (min-width:1000px) {
  .show_pop {
    display: block;

  }

  .show_slide {
    display: none;
  }

  /* ion-menu {
    opacity: 0;
  } */
}

.link_cls {
  text-decoration: none;
  color: var(--ion-color-light-contrast);
  font-size: '15px'

}

.custom-modal-deity {
  max-height: 50vh;
  /* Set your desired fixed height here */
  overflow-y: auto;
  z-index: 1050;
  padding: 10px 0px;
  /* Adjust the z-index value based on your requirements */
}

.custom-modal {
  max-height: 70vh;
  /* Set your desired fixed height here */
  overflow-y: auto;
  z-index: 1050;
  /* Adjust the z-index value based on your requirements */
}

/*____________New UI_____________*/
.user-profile {
  width: 120px;
  height: 120px;
  border-radius: 86px;

}

.profile-userName {
  font-size: 34px;
  color: #222;
  font-style: normal;
  font-weight: 700;
}

.profile-mail {

  font-size: 23px;
  font-weight: 400;
  color: #5B5B5B;

}

.userprofile-container {
  padding-left: 7%;
  padding-right: 7%;
  background: #F5F5F5 !important;
}

.admin-userprofile {
  padding-left: 3%;
  padding-right: 1.5%;
  background: #F5F5F5 !important;
}

.admin_profile_content {
  display: flex;
  justify-content: center;
}

.user-profile-list {
  --inner-border-width: 0 0 0px 0 !important;
}

.userprofile-links {
  text-decoration: none;
  color: #404040;
  font-size: 23px;
  margin: 15px 0px;
  font-weight: 700;
  line-height: 43px;

}

.userprofile-links:hover {
  color: #d8d9da;

}

.form-container {
  background: #FFFEFE;
  padding: 6% 6%;
  border-radius: 9px;
  margin: 6% 0;
}

.list-container * {
  background: #F5F5F5;
  margin: 10px 0px;

}

.form-labels {
  color: #484848;
  font-weight: 900;
  font-style: normal;
  ;
  font-size: 16px;
  line-height: normal;


}

.profile-inputs {
  width: 346px !important;
  border-radius: 8px;


  margin: 5px 0px !important;
  ;
  font-size: 16px;
  font-weight: 500;
}

.phone_disable {
  pointer-events: none;

}

.signup-autoSelect {
  width: 346px !important;
  background: white;
  border: 1px solid var(--border-color) !important;
  margin: 5px 0px !important;
  border-radius: 8px;
}

.signup-autoSelect>div {
  color: black;
  border-radius: 8px !important;
}

.profile-autoSelect {
  width: 346px !important;
  border-radius: 8px;
  background: white;
  border: 1px solid var(--border-color) !important;
  margin: 5px 0px !important;
}

.profile-autoSelect>div {

  color: black;
  border-radius: 8px !important;
}

.list-container ion-list>div:first-child .userprofile-links {
  color: #576F9F;
  font-size: 26px;
}

.gab-between-inputs {
  margin: 5px 0px;
}


.form-title {
  font-weight: 700;
  margin: 0px 0px 20px 0px;
}

.profile-errors {
  color: red;
  font-size: 12px;
  display: flex;
  padding-top: 2px;
  justify-content: left
}

.update {
  background-color: #48C13E !important;
}

.centerBtn {
  display: flex;
  justify-content: center;
}

.profile-modal .modal-dialog {
  margin-top: 140px;
  padding: 2%;
}

.profile-modal .modal-content {
  padding-top: 65px;
  padding-left: 65px;
  padding-right: 45px;
  border-radius: 13px;
  width: 549px;
  height: 425px;
  background: #FFF;
  margin: 0 auto;

}

.profile-modal .modal-body {
  padding: 0%;
}

.profile-modal .profile-inputs {
  height: 45px;
}

.profile-modal ion-button {
  margin-top: 50px;
}

.profileModal-title {
  font-size: 25px;
  font-weight: 600;

  color: #222;
}

.profilenodal-Header {
  border-bottom: none !important;

  padding: 20px 0px !important;
}

.changeNum-btn {
  margin: 10% 0%;
  width: 200px;
  border-radius: 49px;
  height: 50px;
  font-size: 20px;
}

@media only screen and (min-width: 1090px) and (max-width: 1260px) {
  .profile-inputs {
    width: 300px !important;
  }

  .profile-autoSelect {
    width: 300px !important;
  }


}

@media only screen and (min-width: 992px) and (max-width: 1089px) {
  .profile-inputs {
    width: 270px !important;
  }

  .profile-autoSelect {
    width: 270px !important;
  }
}

@media only screen and (max-width:1200px) {
  .profile-userName {
    font-size: 24px;
    color: #222;
    font-style: normal;
    font-weight: 700;
  }

  .profile-mail {
    font-size: 18px;
    font-weight: 400;
    color: #5B5B5B;
  }

  .userprofile-links {
    font-size: 18px;
  }
}

@media only screen and (min-width:992px) {

  .gab-between-inputs ion-col {
    display: flex;
    justify-content: center;
  }

  .form-title {
    width: 346px;
  }
}

@media only screen and (max-width:992px) {
  .list-container {
    display: none;
  }

  .form-container {
    padding: 6% 0%;
  }

  .gab-between-inputs {
    width: 65%;
    margin: 5px auto;
  }

  .form-title {
    width: 90%;
  }
}

@media only screen and (max-width:768px) {
  .profile-modal .modal-content {
    width: 100%;
  }
}



@media only screen and (max-width:550px) {

  .gab-between-inputs {
    width: 90%;
    margin: 5px auto;
  }

  .form-title {
    width: 100%;
    margin: 5px auto;
    font-size: 22px !important;
  }

  .profile-inputs {
    width: 100% !important;
  }

  .profile-autoSelect {
    width: 100% !important;
  }
  .signup-autoSelect {
    width: 270px !important;
  }

  .form-container h2,
  .profileModal-title {
    font-size: 24px;
  }

  .profile-modal .modal-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1000px) {
  .mini-sidebar .admin-userprofile {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .admin-userprofile {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

.sc-ion-input-md-h {
  z-index: 0 !important;
}
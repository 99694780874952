
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
}
.ce {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
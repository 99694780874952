.tab_content_container .textArea_v2 * {
    height: 120px;
    overflow-y: auto !important;
    resize: none;
}

.tab_content_container ion-label {
    font-size: 16px;
    font-weight: 900;
}

.modal_container .textArea_v2 * {
    height: 70px;
    overflow-y: auto !important;
    resize: none;
}

.modal_container .ion-select-popover {
    z-index: 1055 !important;
    /* Ensure it is higher than the modal's z-index */
}

.modal_container .ion-select-popover-interface {
    z-index: 1060 !important;
    /* Ensure it is even higher for the popover content */
}

.radio_checkbox_container input {
    pointer-events: fill !important;
}

.expandable-row-modal .modal-dialog {
    overflow: initial;
    min-width: auto !important;
}

.expandable-row-modal .modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.label-fonts {
    font-size: 18px;
}

.modal-header {
    padding-bottom: 0px !important;
}

.poojaBooking-modal,
.modal-dialog {
    min-width: 55% !important;
}

.newCardV2 {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.newCardV2 .card-header-v2 {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 1px solid #ddd;
    background-color: var(--bs-card-cap-bg);
}

.newCardV2 .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
}

.newCardV2 .dropdown-icon {
    cursor: pointer;
    font-size: 24px;
    color: #333;
}

.newCardV2 .card-body {
    padding: 15px;
}

.newCardV2 .card-row {
    margin-bottom: 10px;
}

.newCardV2 .card-details {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.nested-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nested-card {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nested-card-body {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.nested-card-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
}

.nested-card-col {
    width: 48%;
    /* Each column takes up 48% width */
    padding: 8px;
}

.nested-card-key {
    font-weight: bold;
    flex: 1;
}

.nested-card-value {
    flex: 2;
    text-align: left;
}

.adm_image_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nodataavailabletext {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Full viewport height */
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    /* Optional, for better visibility */
}


/* local market */
.product-grid {
    padding: 50px 20px;
    display: flex;
    justify-content: space-evenly;
    height: 80dvh;
}

.product-card {
    width: 300px;
    height: 400px;
    margin: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    background: white;
}

.product-image-container {
    height: 200px;
    width: 100%;
    overflow: hidden;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Full height of the viewport */
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #555;
}

.product-content {
    padding: 15px;
    height: calc(200px - 30px);
    /* Remaining height minus padding */
    display: flex;
    flex-direction: column;
}

.product-title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.product-details {
    font-size: 14px;
    color: #666;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.product-price {
    font-size: 18px;
    font-weight: 600;
    color: #2563eb;
}

.product-modal-container {
    display: flex;
    flex-direction: row;
    /* Arrange image and details side by side */
    gap: 20px;
}

.product-modal-image {
    flex: 1 1;
    max-width: 40%;
    justify-content: center;
    display: flex;

}

.product-modal-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.product-modal-details {
    flex: 2;
    max-width: 60%;
    /* Details take up the remaining 60% */
}

.product-modal-details h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.product-modal-details p {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.product-modal-details strong {
    font-weight: bold;
}

.common_info_modal .modal-body {
    background-color: #b7cae8;
}


.horizontal_padding_v2 {
    padding: 40px;
}

.cart-list-parent {
    width: 500px;
    margin: 0 auto;
}

.cart-list-item {
    border: 1px solid #ddd;
    margin: 10px 0px;
}

.date_input {
    max-width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

.password_textBox {
    color: #737272;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    width: 346px;
    height: 45px;
    flex-shrink: 0;
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 15px;
    cursor: pointer;
    font-size: 20px;
    color: gray;
    top: 50%;
}

.google-signup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: fit-content;
}

.google-signup:hover {
    background-color: #f0f0f0;
}

.google-icon {
    font-size: 24px;
    color: #ea4335;
}

.google-signup span {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.forwordGo {
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    background-color: var(--ion-color-primary);
    padding: 8px 10px;
    border-radius: 10px;
}

@media (max-width: 580px) {
    .horizontal_padding_v2 {
        padding: 10px;
    }

    /* Mobile view for the card */
    .card {
        margin: 10px 0;
    }

    .card-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .card-title {
        font-size: 16px;
    }

    .dropdown-icon {
        margin-top: 10px;
    }

    .nested-details {
        flex-direction: column;
    }

    .nested-card {
        width: 100%;
        padding: 12px;
    }

    /* .nested-card-row {
        flex-direction: column;
        align-items: flex-start;
    } */

    .nested-card-key,
    .nested-card-value {
        text-align: left;
        font-size: 14px;
    }

    .cart-list-parent {
        width: 100%;
    }
}
.bottom_border_list {
    border-bottom: 1px solid;
    margin-left: 2%;
}

.cartcard {
    margin: 20px 60px;
    border-radius: 7px 7px 7px 7px;
    background-color: white !important;


}

.ordercard {
    margin: 20px 120px;
    border-radius: 7px 7px 0px 0px;
    background-color: white !important;
    color: white;
}

/* .ordercard ion-text {
    color: black;
} */

.viewstatus-btn {
    font-size: 0.8rem;
    margin: 0px !important;
}

.offeringmodal {
    --bs-modal-margin: 0% !important;
}

.Myorders-font {
    font-size: 14px;
}

.cancel_btn_align {
    text-align: left;
}

.mygramerce-modal .modal-content {
    width: 45%;
    margin-left: 30%;
    margin-right: 20%;
}

.action_div_adjust {
    padding: 0px 50px;
}

.action_div_adjust1 {
    padding: 0px 30px;
}

.reason_textarea {
    height: 60px !important;
    overflow-y: auto;

    border-radius: 8px;
}

.order_row {
    padding-left: 15px;
}

.order_pad {
    padding: 20px;
}


.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.order_id {
    color: #222;

    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    padding-left: 5px;
}

.order_detail {
    color: #484848;
    ;
    font-size: 21px;
    font-style: normal;

    line-height: 30px;
}

.order_detail_text {
    color: lightgray;
    ;
    font-size: 21px;
    font-style: normal;

    line-height: 30px;
}

.ord_count {
    color: #858585;
    ;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.del_class {
    border-radius: 49px;
    --background: #FF4949;
    height: 40px;
    flex-shrink: 0;
}

.del_pad {
    color: #FFF;
    ;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    padding: 0px;
}

.circular-background {
    position: relative;
    background-color: #FFF;
    /* Set your desired background color */
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #E6E6E6;

    margin: 5px;
    color: #484848;


    width: 30px;
    /* Adjust the size as needed */
    height: 30px;
    /* Adjust the size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
}

.order_detail_popup {
    color: #484848;
    ;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.no_data {
    font-weight: bold;
    display: flex;
    align-items: center;
    color: darkgray;
    font-size: 30px;
    ;
}

.edit_box {
    border-radius: 10px;
    --background: var(--ion-color-primary);
    height: 40px;
    flex-shrink: 0;
    margin-left: 20px;
}

.edit_icon {
    width: 20px;
    height: 20px;
    color: #FFF;
}

.gra_action_btn_text {}

.gra_cancel_btn {

    --background: #FF4949 !important;

    height: 40px;

}



.gra_action_btn {

    height: 40px;

}



.info_icon_order {

    width: 30px;

    padding-left: 10px;
    cursor: pointer;
}

.pick_date_gram {
    filter: invert(0);
    border-radius: 8px;
}

.pick_time_gram {
    cursor: pointer;
    width: 100%;
    filter: invert(0);

    border-radius: 8px;
}

@media (prefers-color-scheme : dark) {
    .pick_date_gram {

        border-radius: 8px;
    }

    .pick_time_gram {
        cursor: pointer;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid !important;
        border-radius: 8px;
    }
}

@media only screen and (max-width:700px) {
    .pick_date_gram {
        filter: invert(0);
        border-radius: 8px;
        height: 40px;
    }

    .pick_time_gram {
        cursor: pointer;
        width: 100%;
        filter: invert(0);
        height: 40px;
        border-radius: 8px;
    }
}

.order_details_txt {
    text-decoration: underline;
    cursor: pointer;
    color: var(--ion-bold-text-color);
}

.divider_order_details {
    border-color: #DEDEDE;
    min-height: 1px;
}

.order_details_head {
    color: var(--ion-bold-text-color);
    ;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    padding-top: 15px;
}

.material_img {
    width: 170px;
    height: 170px;
    cursor: pointer;
    padding: 10px;
}

.order_status_text {
    color: #404040;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.order_button_padding {
    padding: 0px;
    font-size: 15px;
}

.order_button {
    height: 40px;
    margin-top: 0px !important;
}

.mat_pad {
    padding: 20px;
}

.cart_btn {
    justify-content: left;
    display: flex;
}

.order_status_header_container {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.Order_status_offering {
    max-height: 50vh;
    overflow-y: auto;
}

.offering-modal .modal-content {
    padding: 20px 0px !important;
}

.web_hide_off_order_status {
    display: flex;
}

.order_status_offering_mobile {
    display: none;
}

.order_head_amt {
    color: gray;
    ;
    font-size: 18px;
    font-style: normal;
    line-height: 30px;
}

@media only screen and (max-width:778px) {
    .web_hide_off_order_status {
        display: none;
    }

    .order_status_text {
        color: #404040;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .order_status_offering_mobile {
        display: flex;
    }

    .order_status_col_padding {
        padding: 0% !important;
    }
}

@media only screen and (max-width:370px) {
    .offering-modal .modal-content {
        width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
}

@media only screen and (max-width:670px) {
    .order_status_text {
        color: #404040;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .order_status_header_container {
        padding: 0px 10px;
    }
}

@media only screen and (max-width:573px) {
    .order_status_text {
        color: #404040;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .order_status_header_container {
        padding: 0px 7px;
    }
}

@media (prefers-color-scheme : dark) {
    .divider_order_details {
        border-color: #DEDEDE !important;
        min-height: 0px;
        background-color: #DEDEDE;
    }
}

@media only screen and (max-width : 400px) {
    .material_status_modal .modal-content {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }

    .order_status_text {
        color: #404040;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .order_status_header_container {
        padding: 0px 5px;
    }

    .action_div_adjust {
        padding: 0px 0px;
    }

    .action_div_adjust1 {
        padding: 0px 0px;
    }

    .order_id {
        color: #222;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        padding-left: 0px;

    }



    .order_detail {
        color: #484848;
        ;
        font-size: 16px;
        font-style: normal;

        line-height: 30px;
    }
}

@media only screen and (max-width:630px) {
    .mygramerce-modal .modal-content {
        width: 60%;
        margin-left: 20%;
        margin-right: 15%;
    }
}

@media only screen and (max-width:500px) {
    .mygramerce-modal .modal-content {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media only screen and (max-width:767px) {
    .cancel_btn_align {
        display: flex;
        justify-content: center;
    }

    .ordercard {
        margin: 10px;


        background-color: white !important;
        color: white;
    }

    .cart_btn {
        justify-content: right;
        display: flex;
    }
}

@media only screen and (max-width:450px) {
    .viewstatus-btn {
        font-size: 0.6rem !important;
        width: 90px;
    }

    .Myorders-font {
        font-size: 14px;
    }

    .ordercard {
        margin: 10px;


        background-color: white !important;
        color: white;
    }

    .order_id {
        color: #222;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        padding-left: 0px;

    }



    .order_detail {
        color: #484848;
        ;
        font-size: 16px;
        font-style: normal;

        line-height: 30px;
    }

    .order_detail_text {
        color: lightgray;
        ;
        font-size: 18px;
        font-style: normal;

        line-height: 30px;
    }

    .cartcard {
        margin: 20px 25px;
        border-radius: 7px 7px 7px 7px;
        background-color: white !important;


    }

    .order_button_padding {
        padding: 0px;
        font-size: 12px;
    }

    .mat_pad {
        padding: 5px;
    }

    .order_button {
        width: 110px;
        height: 30px;
    }

    .order_row {
        padding-left: 5px;
    }

    .order_pad {
        padding: 5px;
    }

}

.order_invoice_button {
    --border-radius: 0% !important;
    font-size: 20px !important;
    height: 35px !important;
}
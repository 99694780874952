.heart_div{
    display: flex;
    justify-content: end;
    align-items: flex-end;
  }
  .heart{
    color: rgb(251, 6, 6);
    padding-right: .5%;
  }
  .TextInputStyle{
    border: 1px solid #576F9F;
    height: 30px;
    width: 100%;
  }
  .sc-ion-textarea-md-h {
    margin-top: 0px;
  }
  .TextInputStyle1{
    border: 1px solid #576F9F;
    height: 100px;
    width: 100%;
  }
  .TextInputStyle2{
    border: 1px solid #576F9F;
    width: 100%;
    height: 125px;
  }
  .TextInputStyle3{
    border: 1px solid #576F9F;
    height: 30px;
    width: 100%;
  }

  .div1 {
    display: flex;
    margin-left: 20%;
    margin-right: 20%;
  }
  
  .div2 {
    display: flex;
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
  }
  
  @media only screen and (max-width:700px) {
    .div1 {
      margin-left: 0%;
      margin-right: 0%;
    }
  
    .div2 {
      margin-left: 0%;
      margin-right: 0%;
    }
  }
.heart_div {
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.heart {
  color: rgb(251, 6, 6);
  padding-right: .5%;
}

.TextInputStyle {
  border: 1px solid #576F9F;
  height: 30px;
  width: 100%;
}

.sc-ion-textarea-md-h {
  margin-top: 0px;
}

.TextInputStyle1 {
  border: 1px solid #576F9F;
  height: 100px;
  width: 100%;
}

.TextInputStyle2 {
  border: 1px solid #576F9F;
  width: 100%;
  height: 125px;
}

.TextInputStyle3 {
  border: 1px solid #576F9F;
  height: 30px;
  width: 100%;
}

.div1 {
  display: flex;
  margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%;
}

.div2 {
  display: flex;
  margin-left: 20%;
  margin-right: 20%;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-radio {
  height: auto;
  /* text-align: justify; */
  contain: content;
}

.custom-tabIndex:focus {
  outline: 1px solid black;
}

@media only screen and (max-width:700px) {
  .div1 {
    margin-left: 0%;
    margin-right: 0%;
  }

  .div2 {
    margin-left: 0%;
    margin-right: 0%;
  }
}


/*______________New UI________________*/
.donation-title {
  color: var(--ion-bold-text-color);
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: normal;
}

.donation-container {
  background-color: #fff;
  padding-bottom: 4%;
}

.donation-content {
  font-size: 22px;
  font-weight: 400;
  line-height: 43px;
  font-style: normal;
  margin: 4% 0%;
  color: #404040;
}

.columns-donation {
  padding: 0% 5%;
}

.joinhand-img {
  position: relative;
}

.donation-heart-icons {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}

.donation-supporters {
  display: flex;
  width: 258px;
  height: 121px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 30%;
  right: 0;
  left: -5%;
  bottom: 0;
  padding: 3% 2%;
}

.supporters-card-txt1 {
  font-weight: 700;
  font-size: 23px;
  font-style: normal;
  color: black;
}

.supporters-card-txt2 {
  font-size: 18px;
  color: #848484;
}

.supporters-card-txtParent {
  padding-left: 8%;
}

.contribution-tick {
  max-width: 27px;
  max-height: 27px;
}

.donation-labels-colm {
  display: flex;
  align-items: center;
  padding: 0px;
}

.donation-labels-colm .tick {
  margin-top: 5px;
}

.contribute-labels {
  ;
  font-size: 26px;
  font-style: normal;

  color: #484848;
  margin-left: 3%;
}

.amt-textBox {
  max-width: 451px;
  height: 45px;
  border-radius: 8px;
 
 
}

.contribution-remark {
  width: 719px;
  border-radius: 8px;
  
 
}

.contribute-donation {
  padding: 5%;
}

.revealColumn {
  display: flex;
  align-items: center;
}

.revealColumn ion-checkbox {
  width: 23px;
  height: 23px;
  background: #FFF;
}

.donation-modal-inputs {
  width: 100%;
  height: 45px;
  border-radius: 8px;
 
 

}

.donation-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none !important;
 
  padding: 5% 6% 0% 6%;
}

.donation-modal-title {
  width: 100%;
  color: #404040;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.donation-modal-close-icon {
  width: 16px;
  height: 16px;
}

.donation-modal .contribute-labels {
  font-size: 20px;
  font-style: normal;

  color: #484848;
  margin-left: 10%;
}

.donation-modal .modal-content {
  width: 70%;
  margin: 0 auto;
  border-radius: 13px;
}

.donation-modal .modal-body {
  padding: 0%;
}

.donation-modal-row {
  margin: 4% 0%;
  align-items: baseline;
}

.donation-modal-content {
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 2%;
  padding-top: 2%;
  /* text-align: center; */
}

.address-donation {
  height: 106px;
  overflow-y: auto;
}

.donation-sameas {
  display: flex;
  margin-top: 5%;
  align-items: center;
}

.donation-sameas ion-label {
  color: #404040;
  font-size: 17px;
  margin-left: 20px;

}

.donation-sameas ion-checkbox {
  width: 23px;
  height: 23px;
}

.donation_payment {
  margin-top: 2%;
}

@media only screen and (max-width:1200px) {
  .contribution-remark {
    width: 619px;
  }

  .contribute-labels {
    font-size: 24px;

    margin-left: 2%;
  }

  .donation-supporters {
    top: 20%;
    right: 0;
    left: -5%;
    bottom: 0;
    padding: 3% 2%;
  }

}

.contribution-purpose {
  overflow-y: auto;
  line-height: 20px;
  height: 163px;
  padding: 2%;
}

@media only screen and (max-width:1000px) {
  .donation-modal .modal-content {
    width: 90%;
  }

  .donation-modal-title {
    color: #404040;
    font-size: 35px;
    font-weight: 700;
  }

  .contribute-labels {
    font-size: 20px;

    margin-left: 2%;
  }

  .donation-supporters {
    top: 10%;
    right: 0;
    left: -5%;
    bottom: 0;
    padding: 2% 2%;
  }

  .contribution-remark {
    width: 519px;
  }

  .donation-modal .contribute-labels {
    font-size: 18px;
    font-style: normal;

    color: #484848;
    margin-left: 5%;
  }

  .donation-modal-content {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media only screen and (max-width:768px) {
  .donation-supporters {
    display: none;
  }

  .contribution-remark {
    width: 100%;
  }

  .amt-textBox {
    width: 100%;
  }

  .contribute-labels {
    font-size: 18px;

    margin-left: 2%;
  }

  .contribution-tick {
    max-width: 20px;
    max-height: 20px;
  }

  .contribute-donation {
    padding: 2%;
  }

  .columns-donation {
    padding: 0% 2%;
  }


}

@media only screen and (max-width:700px) {
  .donation-modal .modal-content {
    width: 100%;
  }

  .donation-modal .contribute-labels {
    font-size: 15px;
    font-style: normal;

    color: #484848;
    margin-left: 5%;
  }

  .donation-modal .contribution-tick {
    max-width: 15px;
    max-height: 15px;
  }

  .contribute-labels {
    font-size: 15px;

    margin-left: 2%;
  }

  .donation-title {
    color: var(--ion-bold-text-color);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: normal;

  }

  .donation-modal-content {
    padding-left: 1%;
    padding-right: 1%;
  }

  .donation-modal-title {
    color: #404040;
    font-size: 20px;
    font-weight: 500;
  }

  .donation-sameas ion-label {
    color: #404040;
    font-size: 15px;
    margin-left: 20px;

  }

  .donation-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 43px;
    font-style: normal;
    margin: 4% 0%;
  }
}
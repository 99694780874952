.select_package_admin {
    height: 45px;
    width: 100%;
    color: #737272 !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 5px;
    background-color: white;
}

.material-shipment-row td {
    font-size: 16px;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    word-break: break-word;
    text-align: center;
    border: none;
}

.shipMent-headCells {
    min-width: 150px !important;
    text-align: center;
    color: #404040;
}

.shipMent-headCells:first-child {
    min-width: 100px !important;
}

.shipMent-headCells:nth-child(3) {
    min-width: 120px !important;
}


.shipMent-rowCells:first-child {
    min-width: 100px !important;
}

.shipMent-rowCells:nth-child(3) {
    min-width: 120px !important;
}
.shipMent-rowCells {
    min-width: 150px !important;
    text-align: center;
    
}
.chooseOrderTab{
    cursor: pointer;
    background-color: #c1bdbd;
    flex: 0 0 auto;
    min-width: 120px; /* Set a minimum width for each item */
    
    
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-admin-container {
    margin-bottom: 20px;
}

.select-status-admin {
    background-color: white !important;
}

.material_order_modal .modal-dialog {
    min-width: 70% !important;
}

.product_textarea {
    min-height: 115px !important;
    max-height: 250px !important;
}
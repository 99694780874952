.pro-card {
    width: 70% !important;
    margin: 0 10%;
    border: 1px solid gray;
    height: fit-content;
}

.progress {
    --background: "red";
}

.btns {
    width: 100%;
    padding: 5% 0%;
    --background: white;
}



.modal-dialog {
    min-width: 65% ;
    overflow: initial;
}



.two-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the text to two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* Add any other styles you need for the container */
}

.purpose {
    height: 90px;
    border: 1px solid black;
    margin-left: 5%;
    overflow-y: scroll;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.icon_pur {
    font-size: 20px;
    color: #576F9F;
    cursor: pointer;
    padding-top: 5px;
}

.projCreateLabels {
    justify-content: right;
    display: flex;
    align-items: center;
}

.projCreateDivide {
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.projCreateValues {
    text-align: left;
    display: flex;
    align-items: center;
}

@media only screen and (max-width:1150px) {
    .modal-dialog {
        min-width: 75% !important;
        overflow: initial;
    }
}

@media only screen and (max-width:920px) {
    .pro-card {
        text-align: center;
        width: 80% !important;
        margin: 0 10%;
    }
}

@media only screen and (max-width:800px) {
    .pro-card {
        text-align: center;
        width: 70% !important;
        margin: 0 10%;
    }
}

/* ____ new _____ */
.project-management-search-container {
    text-align: left;
}

.project-management-search-container ion-input,
.project-management-search-container .custom-select {
    width: 90% !important;
}

.project-management-search-container ion-row ion-col:nth-child(2) {
    padding-left: 15px;
}

.search-colum-project-admin {
    display: flex;
    /* justify-content: flex-end; */
    align-items: flex-end;
    margin-bottom: 10px;
}

.admin_project_cards {
    border-radius: 15px;
    border: 1px solid #BCBCBC;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.23);
    background: #FFF;
    text-align: left;
    padding: 4% 5%;
    max-width: 95%;
}

.admin_project_cards .card-header {
    padding: 0% 0%;
}

.admin_project_cards .card-title {
    font-size: 20px;
}

.admin_project_cards .card-content {
    padding: 0% 0%;
    -webkit-line-clamp: 3;
    height: 4.5em;
    font-size: 14px;
}

.status-project-card {
    padding: 20px 0px;
    font-weight: 400;
    color: #404040;
}

.status-project-card ion-text:first-child {
    font-size: 13px;
}

.status-project-card ion-text:nth-child(2) {
    font-size: 16px;

}

.admin_project_cards .card-progressBar {
    width: 100%;
    margin-bottom: 10px;
}

.admin_project_cards .project-rupees {
    font-size: 19px;
}

.collected-editrow ion-col:nth-child(2) {
    display: flex;
    justify-content: flex-end;
}

.admin_project_cards .donate-share {
    display: flex;
    justify-content: flex-end;
}

.adminProject-purpose {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
}

.create-modal-project-container {
    padding: 20px 0px;
}

.create-project-element {
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.create-modal-project-container ion-input {
    height: 35px;
    border-radius: 5px;
    width: 100% !important;
}

.create-modal-project-container ion-textarea {
    height: 114px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100% !important;
}

.slick_nxt {
    font-size: 25px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 100%;
    display: block;
    width: 40px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #69B5DE;
}

.slick_prev {
    font-size: 25px;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 20px;
    right: 100%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #69B5DE;
    padding: 0;

}

.slider-element-admin {
    margin: 3% auto;
    width: 95%;
}

.donation-admin-container ion-col {
    padding: 10px 0px;
}

.donation-admin-container ion-col .label_align {
    line-height: 25px;
}

@media only screen and (max-width:768px) {
    .project-admin-container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (max-width:576px) {
    .project-management-search-container ion-row ion-col:nth-child(2) {
        padding-left: 5px;
    }

    .project-management-search-container ion-input,
    .project-management-search-container .custom-select {
        width: 100% !important;
    }

    .search-colum-project-admin {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin_project_cards .donate-share {
        display: flex;
        justify-content: center;
    }

    .admin_project_cards .project-rupees {
        font-size: 16px;
        margin-bottom: 0px;
    }
}